import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
// import AtypMedium from "./fonts/AtypText-Medium.ttf";
// import MontserratOtf from "./fonts/Montserrat-Regular.otf";


// const atypMedium = {
//   fontFamily: "AtypText-Medium",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `

//     url(${AtypMedium}) format('ttf')
//   `,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      // fontFamily: ['Open Sans'].join(','),
      fontFamily: "AtypText"
    },

    breakpoints: {
      values: {
        sm: 544, // Small screen / phone
        md: 768, // Medium screen / tablet
        lg: 1024, // Large screen / desktop
        xl: 1440,
        xxl: 1640,
      }
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        // main: '#369ff7',
        main: 'rgb(0, 90, 90)',
        contrastText: '#fff',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        // main: '#fd397a',
        main: 'rgb(120, 220, 120)',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        // main: '#fd397a',
        main: 'rgb(199,10,0)',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        // light: will be calculated from palette.primary.main,
        main: '#0abb87',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },

    },
    overrides: {
      MuiButton: {
        raisedPrimary: {
          color: 'white',
        },
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */

    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        raisedPrimary: { color: '#fff' },
        disableRipple: true, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
