import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Card, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import { a11yProps, TabPanel } from '../../../components/other/Tab/TabPanel';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import Preloader from '../../../components/other/Preloader/Preloader';
import RichEditor from '../../../components/formComponents/RichEditor';
import ImageDialog from '../../../components/other/ImageDialog/imageDialog';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useStyles } from './hooks/useStyles';
import { useFormatMessage, useShowErrors } from '../../../hooks';

const CompanyView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  company,
  companyError,
  loading,
  fetch,
  clearDelCompany,
  me,
  meError,
  loadingMe,
  fetchMe,
  clearMe,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const fm = useFormatMessage();

  const [valueTabs, setValueTabs] = useState(0);
  const [isImageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  setLayoutSubheader({ title: fm('COMPANY.TITLE.VIEW') });
  setLayoutFooter({ show: true });

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearDelCompany();
    };
  }, [id]);

  const descriptionHtml = useMemo(() => {
    if (company?.description) {
      return EditorState.createWithContent(stateFromHTML(company.description));
    }
    return EditorState.createEmpty();
  }, [company]);

  const goToEditCompany = useCallback(() => {
    company && history.push(`/companies/edit/${company.id}`);
  }, [history, company]);

  const handleImage = useCallback(
    url => {
      if (isImageOpen) {
        setImageUrl(null);
        setImageOpen(false);
      } else {
        setImageUrl(url);
        setImageOpen(true);
      }
    },
    [isImageOpen, imageUrl]
  );

  const companyInfo: any = useMemo(() => {
    if (company) {
      return {
        Название: company.name || '-',
        'Название юр. лица': company.brand || '-',
        ИНН: company.inn || '-',
        Телефон: company.phone_number || '-',
        Сайт: company.site || '-',
      };
    }
    return {};
  }, [company]);

  useShowErrors([meError, companyError]);

  if (loading || !company || loadingMe) return <Preloader />;

  return (
    <>
      {imageUrl && <ImageDialog open={isImageOpen} handleClose={handleImage} url={imageUrl} />}
      <Paper className={classes.containerPaper}>
        <div className={classes.container}>
          <div className={classes.header}>
            {me?.is_admin && (
              <Button color='primary' variant='contained' onClick={() => goToEditCompany()}>
                {fm('COMPANY.TABLE.BUTTON.EDIT')}
              </Button>
            )}
          </div>

          <div className={classes.containerTop}>
            {companyInfo && (
              <div className={classes.companyCard}>
                {Object.keys(companyInfo).map(key => (
                  <>
                    {companyInfo[key] && (
                      <div key={key} className={classes.listItem}>
                        <p
                          className={classes.listValue}
                          dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                        />
                        <p className={classes.listKey}>{key}</p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>

          <Tabs
            value={valueTabs}
            onChange={(_: any, newValue: number) => setValueTabs(newValue)}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            aria-label='tabs'
          >
            {Boolean(company.description) && (
              <Tab label={fm('PRODUCT.TABLE.DESCRIPTION')} {...a11yProps(0)} />
            )}
          </Tabs>
          {Boolean(company.description) && (
            <TabPanel value={valueTabs} index={0}>
              <div className={classes.containerBot}>
                <RichEditor
                  disabled
                  editorState={descriptionHtml}
                  placeholder=''
                  setEditorState={() => { }}
                  className={classes.richEditor}
                  elevation={0}
                />
              </div>
            </TabPanel>
          )}
        </div>
      </Paper>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    company: state.companies.company,
    companyError: state.companies.byIdError,
    loading: state.companies.byIdLoading,
    byIdSuccess: state.companies.byIdSuccess,
    isAuthorized: state.auth.user != null,

    me: state.profile.me,
    meError: state.profile.error,
    loadingMe: state.profile.loading,
  }),
  {
    fetch: companiesActions.fetchByIdRequest,
    clearDelCompany: companiesActions.clearDelCompany,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyView);
