import axios from 'axios';
import { IFilteredData } from '../interfaces/promocodes';

export const GET_TRANSACTIONS_URL = '/api/transactions';

export function getTransactions(
  page: number,
  perPage: number,
  isFilter: boolean = false,
  filterData?: IFilteredData,
  user_id?: string
) {
  if (isFilter) {
    return axios.get(GET_TRANSACTIONS_URL, {
      params: {
        page,
        per_page: perPage,
        start_date: filterData?.start_date,
        end_date: filterData?.end_date,
      },
    });
  }
  if (user_id) {
    return axios.get(GET_TRANSACTIONS_URL, { params: { page, per_page: perPage, user_id } });
  }
  return axios.get(GET_TRANSACTIONS_URL, {
    params: {
      page,
      per_page: perPage,
    },
  });
}
