import React, {CSSProperties} from 'react';
import clsx from 'clsx';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

interface IProps {
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onPress?: () => {} | void;
  variant?: 'text' | 'outlined' | 'contained';
  style?: CSSProperties;
  classNames?: any;
}

const useStyles = makeStyles({
  isLoading: {
    paddingRight: '3.5rem!important',
  },
});

const noop = () => {};

const ButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'submit',
  disabled = false,
  loading = false,
  onPress = noop,
  classNames,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      type={type}
      variant='contained'
      color='primary'
      disabled={disabled}
      onClick={onPress}
      className={clsx({
        'btn btn-primary btn-elevate kt-login__btn-primary': true,
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
        [classes.isLoading]: loading,
        [classNames]: true,
      })}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
