import { useCallback, useState } from 'react';
import axios from 'axios';
import { useShowErrors } from '../../../hooks/useShowErrors';

export const useAxiosEnterPassword = () => {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState<any>(null);
  const [err, setErr] = useState<any>(null);

  const makeRequest = useCallback((email: string, password: string, login_code?: string) => {
    const baseUrl = '/api/user/login';
    const formData = new FormData();
    formData.append('login', email);
    !login_code && formData.append('password', password);
    login_code && formData.append('login_code', login_code.trim());

    setLoading(true);
    axios
      .post(baseUrl, formData)
      .then(res => {
        setRes(res.data);
      })
      .catch(err => {
        if (err) setErr(err);
      })
      .finally(() => {
        setLoading(false);
        setErr(null);
        setRes(null);
      });
  }, []);

  useShowErrors([err]);

  return { makeRequest, loading, res };
};
