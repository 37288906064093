import React, { useCallback, useState } from 'react';
import { Card, CardContent, Button, CardActions } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { ICategoryTreeTable } from '../interfaces';
import { ICategoryItem } from '../../categories/interfaces';
import { useURLSearchParams } from '../../../../hooks/useURLSearchParams';

type StyledTreeItemProps = {
  select: (id: number) => void;
  selectedCategoryId: number | null;
  item: ICategoryItem;
  classes: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      margin: 0,
      paddingLeft: 20,
      listStyle: 'none',
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color)`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:hover > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    button: {
      '&:active': {
        opacity: 0.2,
      },
    },
    content: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      position: 'relative',
      transition: '.25s ease-out',
    },
    labelContainerChildren: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    labelChildren: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0.7),
      width: '100%',
      padding: 10,
      cursor: 'pointer',
      paddingLeft: 30,
      transition: '.25s ease-out',
    },
    labelIcon: {},
    labelText: {
      fontWeight: 500,
      flexGrow: 1,
      fontSize: 14,
      color: 'inherit',
    },
    activeItem: {
      backgroundColor: 'rgba(120,220,120,0.5)',
      color: theme.palette.primary.main,
    },
    hide: {
      height: 0,
    },
  })
);

export const StyledTreeItem = React.memo(
  ({ item, select, selectedCategoryId, classes }: StyledTreeItemProps) => {
    const [selectChildren, setSelectChildren] = useState<number | null>(0);

    const handleItem = (id: number) => {
      select(id);
      if (id !== selectChildren) {
        setSelectChildren(null);
      }
    };

    const handleRootLabel = useCallback(
      (item: ICategoryItem) => {
        if (item.id) {
          handleItem(item.id);
          if (selectChildren === item.id) {
            setSelectChildren(null);
          } else {
            setSelectChildren(item.id);
          }
        }
      },
      [selectChildren, select]
    );

    return (
      <>
        <li
          onClick={() => handleRootLabel(item)}
          className={clsx(classes.labelRoot, {
            [classes.activeItem]: item.id === selectedCategoryId,
          })}
        >
          <div style={{ position: 'absolute', left: -20 }}>
            {Boolean(item.children?.length) &&
              (item.id === selectChildren ? <ArrowRightIcon /> : <ArrowDropDownIcon />)}
          </div>
          <Typography variant='body2' className={classes.labelText}>
            {item.name}
          </Typography>
        </li>
        <li
          className={clsx(classes.labelContainerChildren, {
            [classes.hide]: item.id === selectChildren,
          })}
        >
          {Array.isArray(item.children)
            ? item.children.map((node, index) => (
                <li
                  key={index.toString()}
                  onClick={() => node.id && handleItem(node.id)}
                  className={clsx(classes.labelChildren, {
                    [classes.activeItem]: selectedCategoryId && node.id === selectedCategoryId,
                  })}
                >
                  <Typography variant='body2' className={classes.labelText}>
                    {node.name}
                  </Typography>
                </li>
              ))
            : null}
        </li>
      </>
    );
  }
);

const FilterCategoriesTree: React.FC<ICategoryTreeTable> = ({
  categories,
  searchProducts,
  resetProducts,
  filter,
  setSearch,
  setSelectedCategoryId,
  selectedCategoryId,
  sortType,
}) => {
  const intl = useIntl();
  const classes = useTreeItemStyles();
  const { setParams, deleteParam } = useURLSearchParams();
  // const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const fetch = useCallback(() => {
    setSearch(true);
    selectedCategoryId && setParams('category', selectedCategoryId.toString());
    selectedCategoryId && localStorage.setItem('category', selectedCategoryId.toString());
    selectedCategoryId &&
      searchProducts({
        page: 1,
        perPage: 12,
        filter,
        categoryId: selectedCategoryId,
        sort_type: sortType,
        search: '',
      });
  }, [selectedCategoryId, filter, sortType]);

  const select = (id: number) => {
    setSelectedCategoryId(id);
  };

  const clear = () => {
    resetProducts();
    setSearch(filter.parameters.length > 0 || !!filter.max_price || !!filter.min_price);
    deleteParam('category');
    setSelectedCategoryId(null);
    localStorage.removeItem('category');
  };

  return (
    <Card className={classes.cardCategory}>
      <CardContent>
        <Typography variant='h5' className={classes.labelText} style={{ marginBottom: 16 }}>
          {intl.formatMessage({ id: 'CATEGORIES.TITLE' })}
        </Typography>
        <ul className={classes.root}>
          {categories.map((item, index) => (
            <StyledTreeItem
              key={index.toString()}
              select={select}
              selectedCategoryId={selectedCategoryId}
              item={item}
              classes={classes}
            />
          ))}
        </ul>
      </CardContent>
      <CardActions className={classes.cardButtons}>
        <Button
          className={clsx(classes.button)}
          color='secondary'
          variant='contained'
          onClick={clear}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })}
        </Button>
        <Button
          disabled={Boolean(!selectedCategoryId)}
          className={clsx(classes.button)}
          color='primary'
          variant='contained'
          onClick={fetch}
        >
          {intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })}
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilterCategoriesTree;
