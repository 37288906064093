import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import TermDialog from './TermDialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  okText?: string;
  cancelText?: string;
  message?: string;
}

const useStyles = makeStyles(() => ({
  cookieText: {
    width: '266px',
    height: '85px',
    fontFamily: 'AtypText',
    fontSize: '17px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'var(--dark-green)',
  },
  cookieTextBold: {
    fontWeight: 'bold',
    color: '#78dc78',
    cursor: 'pointer',
  },
  dialogContent: {
    display: 'flex',
    // width: '326px',
    position: 'fixed',
    bottom: '0',
    // right: '0',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));

const CookiesDialog: React.FC<IAlertDialog> = ({
  open,
  handleClose,
  handleAgree,
  okText = 'OK',
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    !open && setOpenDialog(false);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{ paper: classes.dialogContent }}
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <span className={classes.cookieText}>
              Продолжая просмотр этого сайта, Вы соглашаетесь на обработку файлов cookie в
              соответствии с&nbsp;
              <span className={classes.cookieTextBold} onClick={() => setOpenDialog(true)}>
                Политикой использования файлов cookie
              </span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} variant='contained' color='primary' autoFocus>
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
      <TermDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        contentType='cookie'
      />
    </>
  );
};

export default CookiesDialog;
