import axios from 'axios';
import {
  PRODUCT_TYPES_URL,
  PRODUCT_TYPE_URL,
  getProductTypeByIdUrl,
  getParametersForType,
  editParameterForType,
  addParameterForType,
  delProdyctTypeByIdUrl,
} from '../constants';
import { IProductType, ITypeParameter } from '../interfaces/productType';

export const createType = (data: IProductType) => axios.post(PRODUCT_TYPE_URL, data);

export const editType = (id: number, data: IProductType) =>
  axios.put(getProductTypeByIdUrl(id), data);

export const getProductType = (id: number) => axios.get(getProductTypeByIdUrl(id));

export const getProductTypes = () => axios.get(PRODUCT_TYPES_URL);

export const getParametersById = (id: number) => axios.get(getParametersForType(id));

export const addParameterById = (id: number, data: ITypeParameter) =>
  axios.post(addParameterForType(id), data);

export const editParameterById = (id: number, data: ITypeParameter) =>
  axios.put(editParameterForType(id), data);

export const deleteProductType = (id: number) => axios.delete(delProdyctTypeByIdUrl(id));
