import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import objectPath from 'object-path'
import Topbar from './Topbar'
import HMenu from './HMenu/HMenu'
import AnimateLoading from '../../partials/layout/AnimateLoading'
import KTHeader from '../../_assets/js/header'
import * as builder from '../../ducks/builder'
import {makeStyles} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  subheaderFull: {
    left: '0 !important',
  },
}));

const Header = ({
  menuHeaderDisplay,
  headerAttributes,
  headerClasses,
  isAuthorized,
  me
}) => {
  const headerCommonRef = useRef();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const options = {};
    if (headerCommonRef.current.getAttribute('data-ktheader-minimize') === '1') {
      options['minimize.desktop.on'] = 'kt-header--minimize';
      options['offset.desktop'] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(headerCommonRef.current, options);

    // if (!isAuthorized) {
    //   history.push('/auth/main');
    // }
  }, []);

  return (
    <div
      className={`kt-header kt-grid__item ${headerClasses} ${classes.subheaderFull}`}
      id='kt_header'
      ref={headerCommonRef}
      {...headerAttributes}
    >
      <AnimateLoading />
      {/* <!-- begin: Header Menu --> */}
      {menuHeaderDisplay && <HMenu />}
      {/* <!-- end: Header Menu --> */}

      {/* <!-- begin:: Header Topbar --> */}
      {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
      {!menuHeaderDisplay && <div />}
      <Topbar showBurger={isAuthorized} me={me}/>
      {/* <!-- end:: Header Topbar --> */}
    </div>
  );
};

const mapStateToProps = store => ({
  me: store.profile.me,
  headerClasses: builder.selectors.getClasses(store, {
    path: 'header',
    toString: true,
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: 'header' }),
  menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, 'header.menu.self.display'),
  fluid: objectPath.get(store.builder.layoutConfig, 'header.self.width') === 'fluid',
  isAuthorized: store.auth.user != null,
});

export default connect(mapStateToProps)(Header);
