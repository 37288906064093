import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { checkHash, getResponseMessage } from '../../utils/utils';
import { IServerResponse } from '../../interfaces/server';
import {
  addProduct,
  cartCheckout,
  cartEdit,
  clearCart,
  createCart,
  getCart,
  setProductCount,
} from '../../crud/cart.crud';
import {
  ICart,
  ICartCheckout,
  ICostDelivery,
  IGuestCart,
} from '../../pages/home/cart/interfaces';
import { IStore } from '../../interfaces/store';
import { getCities, getDeliveryCost, getPoints, getStores } from '../../crud/store.crud';
import { IProduct } from '../../interfaces/product';
import { sendEventAddProductCart } from '../../crud/roistat.crud';

const CLEAR_ADD_PRODUCT = 'orders/CLEAR_ADD_PRODUCT';
const ADD_PRODUCT_REQUEST = 'orders/ADD_PRODUCT_REQUEST';
const ADD_PRODUCT_SUCCESS = 'orders/ADD_PRODUCT_SUCCESS';
const ADD_PRODUCT_FAIL = 'orders/ADD_PRODUCT_FAIL';

const CLEAR_CART_REQUEST = 'orders/CLEAR_CART';
const CLEAR_CART_SUCCESS = 'orders/CLEAR_CART_SUCCESS';
const CLEAR_CART_FAIL = 'orders/CLEAR_CART_FAIL';

const GET_OR_CREATE_CART_REQUEST = 'orders/GET_OR_CREATE_CART_REQUEST';
const GET_OR_CREATE_CART_SUCCESS = 'orders/GET_OR_CREATE_CART_SUCCESS';
const GET_OR_CREATE_CART_FAIL = 'orders/GET_OR_CREATE_CART_FAIL';
const GET_OR_CREATE_CART_GUEST_REQUEST = 'orders/GET_OR_CREATE_CART_GUEST_REQUEST';
const CLEAR_CREATE_CART = 'orders/CLEAR_CREATE_CART';

const CLEAR_SET_PRODUCT_COUNT = 'orders/CLEAR_SET_PRODUCT_COUNT';
const SET_PRODUCT_COUNT_REQUEST = 'orders/SET_PRODUCT_COUNT_REQUEST';
const SET_PRODUCT_COUNT_SUCCESS = 'orders/SET_PRODUCT_COUNT_SUCCESS';
const SET_PRODUCT_COUNT_FAIL = 'orders/SET_PRODUCT_COUNT_FAIL';

const CLEAR_CHECKOUT = 'orders/CLEAR_CHECKOUT';
const CHECKOUT_REQUEST = 'orders/CHECKOUT_REQUEST';
const PAYED_REQUEST = 'orders/PAYED_REQUEST';
const PAYED_SUCCESS = 'orders/PAYED_SUCCESS';
const CHECKOUT_SUCCESS = 'orders/CHECKOUT_SUCCESS';
const CHECKOUT_FAIL = 'orders/CHECKOUT_FAIL';

const CLEAR_STORES = 'orders/CLEAR_STORES';
const STORES_REQUEST = 'orders/STORES_REQUEST';
const STORES_SUCCESS = 'orders/STORES_SUCCESS';
const STORES_FAIL = 'orders/STORES_FAIL';
const SET_PRODUCT_GUEST_CART = 'orders/SET_PRODUCT_GUEST_CART';
const CLEAR_GUEST_CART = 'orders/CLEAR_GUEST_CART';
const GET_LOCAL_GUEST_CART = 'orders/GET_LOCAL_GUEST_CART';
const SET_COUNT_GUEST_CART = 'orders/SET_COUNT_GUEST_CART';
const SET_USER_INFO_GUEST_CART = 'orders/SET_USER_INFO_GUEST_CART';

const FETCH_CITIES_REQUEST = 'supply/FETCH_CITIES_REQUEST';
const FETCH_CITIES_SUCCESS = 'supply/FETCH_CITIES_SUCCESS';
const FETCH_CITIES_FAIL = 'supply/FETCH_CITIES_FAIL';

const FETCH_DELIVERY_COST_REQUEST = 'orders/FETCH_DELIVERY_COST_REQUEST';
const FETCH_DELIVERY_COST_SUCCESS = 'orders/FETCH_DELIVERY_COST_SUCCESS';
const FETCH_DELIVERY_COST_FAIL = 'orders/FETCH_DELIVERY_COST_FAIL';
const FETCH_DELIVERY_COST_CLEAR = 'orders/FETCH_DELIVERY_COST_CLEAR';

const FETCH_POINTS_REQUEST = 'supply/FETCH_POINTS_REQUEST';
const FETCH_POINTS_SUCCESS = 'supply/FETCH_POINTS_SUCCESS';
const FETCH_POINTS_FOR_MAP_SUCCESS = 'supply/FETCH_POINTS_FOR_MAP_SUCCESS';
const FETCH_POINTS_FAIL = 'supply/FETCH_POINTS_FAIL';
const CLEAR_POINTS = 'supply/CLEAR_POINTS';
const CLEAR_CART_ADD_PRODUCT = 'orders/CLEAR_CART_ADD_PRODUCT';
const CLEAR_REQUEST = 'orders/CLEAR_REQUEST';

export interface IInitialState {
  cart: ICart | null;
  companyId: number | null;
  stores: IStore[];

  addProductLoading: boolean;
  addProductSuccess: boolean;
  addProductError: string | null;

  getOrCreateCartLoading: boolean;
  getOrCreateCartSuccess: boolean;
  getOrCreateCartError: string | null;

  getCartLoading: boolean;
  getCartSuccess: boolean;
  getCartError: string | null;

  clearCartLoading: boolean;
  clearCartSuccess: boolean;
  clearCartError: string | null;

  setProductCountLoading: boolean;
  setProductCountSuccess: boolean;
  setProductCountError: string | null;

  checkoutLoading: boolean;
  checkoutSuccess: boolean;
  checkoutError: string | null;

  storesLoading: boolean;
  storesSuccess: boolean;
  storesError: string | null;
  guestCart: IGuestCart | null;
  city: any;
  deliveryCost: ICostDelivery | null;
  deliveryCostError: string | null;
  cityLoading: boolean;
  citySuccess: boolean;
  cityError: string | null;
  points: any;
  pointsForMap: any;
  deliveryCostLoading: boolean;
  deliveryCostSuccess: boolean;
  page: number;
  per_page: number;
  total: number;
  pointsLoading: boolean;
}

const initialState: IInitialState = {
  cart: null,
  companyId: null,
  stores: [],

  addProductLoading: false,
  addProductSuccess: false,
  addProductError: null,

  getOrCreateCartLoading: false,
  getOrCreateCartSuccess: false,
  getOrCreateCartError: null,

  getCartLoading: false,
  getCartSuccess: false,
  getCartError: null,

  clearCartLoading: false,
  clearCartSuccess: false,
  clearCartError: null,

  setProductCountLoading: false,
  setProductCountSuccess: false,
  setProductCountError: null,

  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutError: null,

  storesLoading: false,
  storesSuccess: false,
  storesError: null,
  guestCart: null,
  city: undefined,
  points: undefined,
  pointsForMap: undefined,
  cityLoading: false,
  citySuccess: false,
  cityError: null,
  deliveryCost: null,
  deliveryCostError: null,
  deliveryCostLoading: false,
  deliveryCostSuccess: false,
  page: 1,
  per_page: 20,
  total: 0,
  pointsLoading: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'orders', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_ADD_PRODUCT: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_REQUEST: {
        return {
          ...state,
          addProductLoading: true,
          addProductSuccess: false,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_SUCCESS: {
        const cart = action.payload.data;
        const companyId =
          cart && cart.items.length ? cart.items[0].product?.company?.id || null : null;

        return {
          ...state,
          companyId,
          cart,
          addProductLoading: false,
          addProductSuccess: true,
          addProductError: null,
        };
      }
      case ADD_PRODUCT_FAIL: {
        return {
          ...state,
          addProductLoading: false,
          addProductSuccess: false,
          addProductError: action.payload,
        };
      }
      case GET_OR_CREATE_CART_REQUEST: {
        return {
          ...state,
          getOrCreateCartLoading: true,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_SUCCESS: {
        const cart = action.payload.data;
        const companyId =
          cart && cart.items.length ? cart.items[0].product?.company?.id || null : null;

        return {
          ...state,
          companyId,
          cart,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: true,
          getOrCreateCartError: null,
        };
      }
      case GET_OR_CREATE_CART_FAIL: {
        return {
          ...state,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: action.payload,
        };
      }

      case CLEAR_CREATE_CART: {
        return {
          ...state,
          getOrCreateCartLoading: false,
          getOrCreateCartSuccess: false,
          getOrCreateCartError: null,
        };
      }

      case CLEAR_CART_ADD_PRODUCT: {
        localStorage.removeItem('guestCart');
        return {
          ...state,
          getOrCreateCartError: null,
        };
      }

      case CLEAR_CART_REQUEST: {
        return {
          ...state,
          clearCartLoading: true,
          clearCartSuccess: false,
          clearCartError: null,
        };
      }
      case CLEAR_CART_SUCCESS: {
        return {
          ...state,
          companyId: null,
          cart: null,
          guestCart: null,
          clearCartLoading: false,
          clearCartSuccess: true,
          clearCartError: null,
        };
      }
      case CLEAR_CART_FAIL: {
        return {
          ...state,
          clearCartLoading: false,
          clearCartSuccess: false,
          clearCartError: action.payload,
        };
      }

      case CLEAR_SET_PRODUCT_COUNT: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_REQUEST: {
        return {
          ...state,
          setProductCountLoading: true,
          setProductCountSuccess: false,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_SUCCESS: {
        const cart = action.payload.data;
        const companyId = cart && cart.items.length ? state.companyId : null;

        return {
          ...state,
          companyId,
          cart,
          setProductCountLoading: false,
          setProductCountSuccess: true,
          setProductCountError: null,
        };
      }
      case SET_PRODUCT_COUNT_FAIL: {
        return {
          ...state,
          setProductCountLoading: false,
          setProductCountSuccess: false,
          setProductCountError: action.payload,
        };
      }

      case CLEAR_CHECKOUT: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_REQUEST: {
        return {
          ...state,
          checkoutLoading: true,
          checkoutSuccess: false,
          checkoutError: null,
        };
      }
      case CHECKOUT_SUCCESS: {
        // localStorage.setItem('hash', action.payload.data.cart_hash);

        return {
          ...state,
          // cart: action.payload.data,
          // companyId: null,
          checkoutLoading: false,
          checkoutSuccess: true,
          checkoutError: null,
        };
      }
      case CHECKOUT_FAIL: {
        return {
          ...state,
          checkoutLoading: false,
          checkoutSuccess: false,
          checkoutError: action.payload,
        };
      }

      case CLEAR_STORES: {
        return {
          ...state,
          stores: [],
          storesLoading: false,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_REQUEST: {
        return {
          ...state,
          storesLoading: true,
          storesSuccess: false,
          storesError: null,
        };
      }
      case STORES_SUCCESS: {
        return {
          ...state,
          stores: action.payload.data,
          storesLoading: false,
          storesSuccess: true,
          storesError: null,
        };
      }
      case STORES_FAIL: {
        return {
          ...state,
          storesLoading: false,
          storesSuccess: false,
          storesError: action.payload,
        };
      }

      case SET_PRODUCT_GUEST_CART: {
        if (state.guestCart && action.payload.type === 'cart') {
          const newGuestCart = state.guestCart;
          const guestProductIndex = newGuestCart.items.findIndex(
            item => item.product.id === action.payload.data.id
          );
          if (guestProductIndex > -1) {
            newGuestCart.items[guestProductIndex].count += 1;
          } else {
            newGuestCart.items = [
              ...newGuestCart.items,
              {
                count: 1,
                product: action.payload.data,
                product_price: action.payload.data.price,
              },
            ];
          }
          newGuestCart.order_summ += action.payload.data.price;
          newGuestCart.goods_num += 1;
          localStorage.setItem('guestCart', JSON.stringify(newGuestCart));
          return {
            ...state,
            guestCart: newGuestCart,
          };
        }
        const startGuestCart: IGuestCart = {
          order_summ: action.payload.data.price,
          goods_num: 1,
          items: [
            {
              count: 1,
              product: action.payload.data,
              product_price: action.payload.data.price,
            },
          ],
          cartCompanyId: action.payload.data.company?.id,
          fio: '',
          email: '',
          phone: '',
          isPickup: false,
          // weight: action.payload.data.weight,
        };
        localStorage.setItem('guestCart', JSON.stringify(startGuestCart));
        return {
          ...state,
          guestCart: startGuestCart,
        };
      }

      case GET_LOCAL_GUEST_CART: {
        if (state.guestCart) {
          return state;
        }
        const localCart = localStorage.getItem('guestCart');
        if (localCart) {
          return {
            ...state,
            guestCart: JSON.parse(localCart),
          };
        }
        return state;
      }

      case CLEAR_GUEST_CART: {
        localStorage.removeItem('guestCart');
        return {
          ...state,
          guestCart: null,
        };
      }

      case SET_COUNT_GUEST_CART: {
        const currentGuestCart = state.guestCart;
        if (currentGuestCart) {
          const indexCurrentProduct = currentGuestCart.items.findIndex(
            item => item.product.id === action.payload.product_id
          );
          const currentProduct = currentGuestCart.items[indexCurrentProduct];
          action.payload.type === 'dec'
            ? (currentGuestCart.goods_num -= 1)
            : (currentGuestCart.goods_num += 1);
          action.payload.type === 'dec'
            ? (currentGuestCart.order_summ -= currentProduct.product_price)
            : (currentGuestCart.order_summ += currentProduct.product_price);
          // action.payload.type === 'dec'
          //   ? (currentGuestCart.weight -= currentProduct?.weight)
          //   : (currentGuestCart.weight += currentProduct?.weigth);
          action.payload.count <= 0
            ? currentGuestCart.items.splice(indexCurrentProduct, 1)
            : (currentProduct.count = action.payload.count);

          if (currentGuestCart.items.length === 0) {
            currentGuestCart.goods_num = 0;
          }
          localStorage.setItem('guestCart', JSON.stringify(currentGuestCart));
          return {
            ...state,
            guestCart: { ...currentGuestCart },
          };
        }
        return state;
      }
      case SET_USER_INFO_GUEST_CART: {
        const currentCart = state.guestCart;
        if (currentCart) {
          const newCurrentCart = {
            ...currentCart,
            fio: action.payload.fio,
            email: action.payload.email,
            phone: action.payload.phone,
            isPickup: action.payload.isPickup,
            pickupStore: action.payload.pickupStore,
          };
          localStorage.setItem('guestCart', JSON.stringify(newCurrentCart));
          return {
            ...state,
            guestCart: newCurrentCart,
          };
        }
        return state;
      }

      case FETCH_CITIES_REQUEST: {
        return {
          ...state,
          cityLoading: true,
          citySuccess: false,
          cityError: null,
        };
      }
      case FETCH_CITIES_SUCCESS: {
        return {
          ...state,
          city: action.payload.data,
          cityLoading: false,
          citySuccess: true,
          cityError: null,
        };
      }
      case FETCH_CITIES_FAIL: {
        return {
          ...state,
          cityLoading: false,
          citySuccess: false,
          cityError: action.payload,
        };
      }

      case FETCH_DELIVERY_COST_REQUEST: {
        return {
          ...state,
          deliveryCostLoading: true,
          deliveryCostSuccess: false,
          deliveryCostError: null,
        };
      }
      case FETCH_DELIVERY_COST_SUCCESS: {
        return {
          ...state,
          deliveryCost: action.payload.data,
          deliveryCostLoading: false,
          deliveryCostSuccess: true,
          deliveryCostError: null,
        };
      }
      case FETCH_DELIVERY_COST_FAIL: {
        return {
          ...state,
          deliveryCostLoading: false,
          deliveryCostSuccess: false,
          deliveryCostError: action.payload,
        };
      }

      case FETCH_DELIVERY_COST_CLEAR: {
        return {
          ...state,
          deliveryCost: null,
          deliveryCostLoading: false,
          deliveryCostSuccess: false,
          deliveryCostError: null,
        };
      }

      case FETCH_POINTS_REQUEST: {
        return {
          ...state,
          pointsLoading: true,
          pointsSuccess: false,
          pointsError: null,
        };
      }
      case FETCH_POINTS_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          points: action.payload.data,
          pointsLoading: false,
          pointsSuccess: true,
          pointsError: null,
        };
      }
      case FETCH_POINTS_FOR_MAP_SUCCESS: {
        return {
          ...state,
          total: action.payload.total,
          pointsForMap: action.payload.data,
          pointsLoading: false,
          pointsSuccess: true,
          pointsError: null,
        };
      }
      case CLEAR_REQUEST: {
        return { ...state, page: 1, per_page: 20 };
      }

      case CLEAR_POINTS: {
        return {
          ...state,
          points: undefined,
        };
      }

      case FETCH_POINTS_FAIL: {
        return {
          ...state,
          pointsLoading: false,
          pointsSuccess: false,
          pointsError: action.payload,
        };
      }

      case PAYED_REQUEST: {
        return {
          ...state,
        };
      }

      case PAYED_SUCCESS: {
        localStorage.setItem('hash', action.payload.data.cart_hash);

        return {
          ...state,
          cart: action.payload.data,
          companyId: null,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearAddProduct: () => createAction(CLEAR_ADD_PRODUCT),
  addProductRequest: (payload: { product_id: number; count: number; newCart?: boolean }) =>
    createAction(ADD_PRODUCT_REQUEST, payload),
  addProductSuccess: (payload: IServerResponse<ICart>) =>
    createAction(ADD_PRODUCT_SUCCESS, payload),
  addProductFail: (payload: string) => createAction(ADD_PRODUCT_FAIL, payload),

  getOrCreateRequest: () => createAction(GET_OR_CREATE_CART_REQUEST),
  getOrCreateSuccess: (payload: IServerResponse<ICart>) =>
    createAction(GET_OR_CREATE_CART_SUCCESS, payload),
  getOrCreateFail: (payload: string) => createAction(GET_OR_CREATE_CART_FAIL, payload),
  getOrCreateGuestRequest: () => createAction(GET_OR_CREATE_CART_GUEST_REQUEST),

  clearCartRequest: () => createAction(CLEAR_CART_REQUEST),
  clearCartSuccess: () => createAction(CLEAR_CART_SUCCESS),
  clearCartFail: (payload: string) => createAction(CLEAR_CART_FAIL, payload),

  clearSetProductCount: () => createAction(CLEAR_SET_PRODUCT_COUNT),
  setProductCountRequest: (payload: {
    product_id: number;
    count: number;
    type: 'dec' | 'inc';
  }) => createAction(SET_PRODUCT_COUNT_REQUEST, payload),
  setProductCountSuccess: (payload: IServerResponse<ICart>) =>
    createAction(SET_PRODUCT_COUNT_SUCCESS, payload),
  setProductCountFail: (payload: string) => createAction(SET_PRODUCT_COUNT_FAIL, payload),

  clearCheckout: () => createAction(CLEAR_CHECKOUT),
  checkoutRequest: (payload: { data: ICartCheckout }) =>
    createAction(CHECKOUT_REQUEST, payload),
  checkoutSuccess: () => createAction(CHECKOUT_SUCCESS),
  checkoutFail: (payload: string) => createAction(CHECKOUT_FAIL, payload),

  clearStores: () => createAction(CLEAR_STORES),
  storesRequest: (payload: { page: number; perPage: number; companyId?: number }) =>
    createAction(STORES_REQUEST, payload),
  storesSuccess: (payload: IServerResponse<IStore[]>) => createAction(STORES_SUCCESS, payload),
  storesFail: (payload: string) => createAction(STORES_FAIL, payload),
  setProductGuestCart: (payload: { data: IProduct; type?: 'new' | 'cart' }) =>
    createAction(SET_PRODUCT_GUEST_CART, payload),
  clearGuestCart: () => createAction(CLEAR_GUEST_CART),
  getLocalGuestCart: () => createAction(GET_LOCAL_GUEST_CART),
  setCountGuestCart: (payload: { product_id: number; count: number; type: 'dec' | 'inc' }) =>
    createAction(SET_COUNT_GUEST_CART, payload),
  setInfoUserGuestCart: (payload: {
    fio: string;
    email: string;
    phone: string;
    isPickup: boolean;
    pickupStore?: IStore | null;
  }) => createAction(SET_USER_INFO_GUEST_CART, payload),

  fetchCities: (payload: string) => createAction(FETCH_CITIES_REQUEST, payload),
  fetchCitiesSuccess: (payload: IServerResponse<any>) =>
    createAction(FETCH_CITIES_SUCCESS, payload),
  fetchCitiesFail: (payload: string) => createAction(FETCH_CITIES_FAIL, payload),
  fetchDeliveryCost: (payload: { id: number; city?: string }) =>
    createAction(FETCH_DELIVERY_COST_REQUEST, payload),
  fetchDeliveryCostSuccess: (payload: IServerResponse<any>) =>
    createAction(FETCH_DELIVERY_COST_SUCCESS, payload),
  fetchDeliveryCostFail: (payload: string) => createAction(FETCH_DELIVERY_COST_FAIL, payload),
  fetchDeliveryCostClear: () => createAction(FETCH_DELIVERY_COST_CLEAR),
  fetchPoints: (payload: {
    city: string;
    page: number;
    perPage: number;
    lng?: number;
    lat?: number;
    isDataForMap?: boolean;
  }) => createAction(FETCH_POINTS_REQUEST, payload),
  fetchPointsSuccess: (payload: IServerResponse<any>) =>
    createAction(FETCH_POINTS_SUCCESS, payload),
  fetchPointsForMapSuccess: (payload: IServerResponse<any>) =>
    createAction(FETCH_POINTS_FOR_MAP_SUCCESS, payload),
  fetchPointsFail: (payload: string) => createAction(FETCH_POINTS_FAIL, payload),
  clearPoints: () => createAction(CLEAR_POINTS),
  clearAdd: () => createAction(CLEAR_CART_ADD_PRODUCT),
  clearCreateCart: () => createAction(CLEAR_CREATE_CART),
  clearRequest: () => createAction(CLEAR_REQUEST),
  payedRequest: () => createAction(PAYED_REQUEST),
  payedSuccess: (payload: IServerResponse<ICart>) => createAction(PAYED_SUCCESS, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* addProductSaga({
  payload,
}: {
  payload: { product_id: number; count: number; newCart?: boolean };
}) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (hash) {
      if (payload.newCart) {
        yield call(() => clearCart(hash));
      }
      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        addProduct({ product_id: payload.product_id, count: payload.count, hash })
      );
      sendEventAddProductCart({
        product_id: payload.product_id,
        hash,
        count: payload.count,
        user: {
          id: data.data.user.id,
          email: data.data.user.email,
          phone: data.data.user.phone,
        },
      });
      yield put(actions.addProductSuccess(data));
      yield put(actions.clearAddProduct());
    }
  } catch (e) {
    yield put(actions.addProductFail(getResponseMessage(e)));
  }
}

function* getOrCreateCart() {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (!hash) {
      const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart());
    }
    yield put(actions.getOrCreateGuestRequest());
  } catch {
    const { data: createdCart }: { data: IServerResponse<any> } = yield call(() =>
      createCart()
    );
    yield put(actions.getOrCreateGuestRequest());
  }
}

function* getOrCreateCartSaga() {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (hash) {
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        yield call(() =>
          cartEdit(parseGuestCart.fio, parseGuestCart.email, parseGuestCart.phone, hash)
        );
        for (const item of parseGuestCart.items) {
          const { data }: { data: IServerResponse<ICart> } = yield call(() =>
            addProduct({ product_id: item.product.id!, count: item.count, hash })
          );
          sendEventAddProductCart({
            product_id: item.product.id!,
            hash,
            count: item.count,
            user: {
              id: data.data.user.id,
              email: data.data.user.email,
              phone: data.data.user.phone,
            },
          });
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    } else {
      const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart());
      yield put(actions.getOrCreateSuccess(data));
      const newHash = data.data.cart_hash;
      localStorage.setItem('hash', newHash);
      const guestCart = localStorage.getItem('guestCart');
      const parseGuestCart: IGuestCart | null = guestCart ? JSON.parse(guestCart) : null;
      if (parseGuestCart) {
        const { data: createdCart }: { data: IServerResponse<any> } = yield call(() =>
          createCart()
        );
        const hash = createdCart.data.cart_hash;
        localStorage.setItem('hash', hash);
        yield call(() =>
          cartEdit(parseGuestCart.fio, parseGuestCart.email, parseGuestCart.phone, hash)
        );
        for (const item of parseGuestCart.items) {
          const { data }: { data: IServerResponse<ICart> } = yield call(() =>
            addProduct({ product_id: item.product.id!, count: item.count, hash })
          );
          sendEventAddProductCart({
            product_id: item.product.id!,
            hash,
            count: item.count,
            user: {
              id: data.data.user.id,
              email: data.data.user.email,
              phone: data.data.user.phone,
            },
          });
        }
        const { data }: { data: IServerResponse<ICart> } = yield call(() => getCart(hash));
        yield put(actions.getOrCreateSuccess(data));
      } else {
        yield put(actions.getOrCreateSuccess(data));
      }
      yield put(actions.clearGuestCart());
    }
  } catch (e) {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    const { data }: { data: IServerResponse<ICart> } = yield call(() =>
      getCart(hash || undefined)
    );
    yield put(actions.getOrCreateSuccess(data));
    yield put(actions.getOrCreateFail(getResponseMessage(e)));
  }
}

function* clearCartSaga() {
  try {
    const hash = localStorage.getItem('hash');
    if (hash) {
      // yield call(() => clearCart(hash));
      localStorage.removeItem('hash');
      yield put(actions.clearCartSuccess());
    }
  } catch (e) {
    yield put(actions.clearCartFail(getResponseMessage(e)));
  }
}

function* setProductCountSaga({
  payload,
}: {
  payload: { product_id: number; count: number; type: 'dec' | 'inc' };
}) {
  try {
    const hash: string | null = yield call(() => checkHash());
    // const hash = localStorage.getItem('hash');
    if (hash) {
      const { data }: { data: IServerResponse<ICart> } = yield call(() =>
        setProductCount({ product_id: payload.product_id, count: payload.count, hash })
      );
      if (payload.type === 'inc') {
        sendEventAddProductCart({
          product_id: payload.product_id,
          hash,
          count: 1,
          user: {
            id: data.data.user.id,
            email: data.data.user.email,
            phone: data.data.user.phone,
          },
        });
      }
      yield put(actions.setProductCountSuccess(data));
      yield put(actions.clearSetProductCount());
    }
  } catch (e) {
    yield put(actions.setProductCountFail(getResponseMessage(e)));
  }
}

function* checkoutSaga({ payload }: { payload: { data: ICartCheckout } }) {
  try {
    // const hash = localStorage.getItem('hash');
    const hash: string | null = yield call(() => checkHash());
    if (hash) {
      const { data: cart }: { data: IServerResponse<any> } = yield call(() =>
        cartCheckout(payload.data, hash)
      );

      // yield put(
      //   actions.fetchDeliveryCost({ id: cart.data.id, city: cart.data.city || undefined })
      // );
      // const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
      // yield put(actions.checkoutSuccess(data));
      yield put(actions.checkoutSuccess());
    }
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* payedCartSaga() {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() => createCart());
    yield put(actions.payedSuccess(data));
  } catch (e) {
    yield put(actions.checkoutFail(getResponseMessage(e)));
  }
}

function* storesFetchSaga({
  payload,
}: {
  payload: { page: number; perPage: number; companyId?: number };
}) {
  try {
    const { data }: { data: IServerResponse<IStore[]> } = yield call(() =>
      getStores(payload.page, payload.perPage, payload.companyId)
    );
    yield put(actions.storesSuccess(data));
  } catch (e) {
    yield put(actions.storesFail(getResponseMessage(e)));
  }
}

function* fetchCitySaga({ payload }: { payload: string }) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() =>
      getCities({ search: payload })
    );
    yield put(actions.fetchCitiesSuccess(data));
  } catch (e) {
    yield put(actions.fetchCitiesFail(getResponseMessage(e)));
  }
}

function* deliveryCostFetchSaga({ payload }: { payload: { id: number; city?: string } }) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() =>
      getDeliveryCost({ order_id: payload.id, city: payload.city })
    );
    yield put(actions.fetchDeliveryCostSuccess(data));
  } catch (e) {
    yield put(actions.fetchDeliveryCostFail(getResponseMessage(e)));
  }
}

function* fetchPointsSaga({
  payload,
}: {
  payload: {
    city: string;
    page: number;
    perPage: number;
    lng?: number;
    lat?: number;
    isDataForMap?: boolean;
  };
}) {
  try {
    const { data }: { data: IServerResponse<any> } = yield call(() =>
      getPoints({
        city: payload.city,
        page: payload.page,
        per_page: payload.perPage,
        lat: payload.lat,
        lng: payload.lng,
      })
    );
    yield put(actions.fetchPointsSuccess(data));
    const { data: dataMap }: { data: IServerResponse<any> } = yield call(() =>
      getPoints({
        city: payload.city,
        page: payload.page,
        per_page: data.total,
        lat: payload.lat,
        lng: payload.lng,
      })
    );
    yield put(actions.fetchPointsForMapSuccess(dataMap));
    // if (payload.isDataForMap) {
    //   yield put(actions.fetchPointsForMapSuccess(data));
    // } else {
    //   yield put(actions.fetchPointsSuccess(data));
    // }
  } catch (e) {
    yield put(actions.fetchPointsFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.addProductRequest>>(
    ADD_PRODUCT_REQUEST,
    addProductSaga
  );
  yield takeLatest<ReturnType<typeof actions.getOrCreateRequest>>(
    GET_OR_CREATE_CART_REQUEST,
    getOrCreateCart
  );
  yield takeLatest<ReturnType<typeof actions.getOrCreateRequest>>(
    GET_OR_CREATE_CART_GUEST_REQUEST,
    getOrCreateCartSaga
  );
  yield takeLatest<ReturnType<typeof actions.clearCartRequest>>(
    CLEAR_CART_REQUEST,
    clearCartSaga
  );
  yield takeLatest<ReturnType<typeof actions.setProductCountRequest>>(
    SET_PRODUCT_COUNT_REQUEST,
    setProductCountSaga
  );
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest<ReturnType<typeof actions.checkoutRequest>>(PAYED_REQUEST, payedCartSaga);
  yield takeLatest<ReturnType<typeof actions.fetchDeliveryCost>>(
    FETCH_DELIVERY_COST_REQUEST,
    deliveryCostFetchSaga
  );
  yield takeLatest<ReturnType<typeof actions.storesRequest>>(STORES_REQUEST, storesFetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchCities>>(
    FETCH_CITIES_REQUEST,
    fetchCitySaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchPoints>>(
    FETCH_POINTS_REQUEST,
    fetchPointsSaga
  );
}
