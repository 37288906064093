import React from 'react';

interface IProps {
  size?: string;
  color?: string
}

const EditeIcon: React.FC<IProps> = ({ size, color='white' }) => {
  return (
    <>
     <svg  width={size || '24px'} height={size || '24px'} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 20h9"/>
        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"/>
    </svg>
    </>
  );
};

export default EditeIcon;
