import axios from 'axios';
import {
  CREATE_PROMOCODE_URL,
  editPromocodeUrl,
  getPromocodeUrl,
  activatePromocodeUrl,
  getPromocodesUrl,
} from '../constants';

import { IPromocodeEditProps, IPromocodeAddProps } from '../interfaces/promocodes';

export function getPromocodes(
  start_date?: string,
  end_date?: string,
  page?: number,
  perPage?: number,
  isFilter: boolean = false,
  by_user?: boolean
) {
  if (start_date || end_date || isFilter) {
    return axios.get(getPromocodesUrl(), {
      params: {
        page,
        perPage,
        start_date,
        end_date,
        by_user,
      },
    });
  }
  return axios.get(getPromocodesUrl(), {
    params: {
      page,
      perPage,
      by_user,
    },
  });
}

export function createPromocode(data: IPromocodeAddProps) {
  return axios.post(CREATE_PROMOCODE_URL, data);
}

export function editPromocode(id: number, data: IPromocodeEditProps) {
  return axios.put(editPromocodeUrl(id), data);
}

export function getPromocode(id: number) {
  return axios.get(getPromocodeUrl(id));
}

export function activatePromocode(code: string) {
  return axios.post(activatePromocodeUrl(code));
}

// export function getPromocodeStat(id: number) {
//   return axios.get(getPromocodeStatUrl(id));
// }

export function getPromocodeSettings() {
  return axios.get('/api/promo_codes/settings');
}

export function editPromocodeSettings(data: { amount: number; bonus: number }) {
  return axios.put(
    '/api/promo_codes/settings',
    {},
    {
      params: {
        amount: data.amount,
        bonus: data.bonus,
      },
    }
  );
}

export function getReferral (){
  return axios.get('/api/promo_code_stat')
}

export function addBonusesUser(user_id: number, amount: number, comment: string) {
  return axios.post('/api/transaction', {
    amount,
    user_id,
    comment,
  });
}
