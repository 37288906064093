import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { IEditPublication, IPublication } from '../../interfaces';
import { useFormatMessage , useDefineUserRole } from '../../../../../hooks';

const getValidationScheme = () => {
  const fm = useFormatMessage();

  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
  });
};

const getInitValue = (data: Partial<IPublication> | null) => {
  return {
    title: data?.title || '',
    description: data?.description || '',
    text: data?.text || '',
    type: data?.type || '',
    favorite: data?.favorite || false,
    category: data?.category,
    product_id: data?.product?.id,
    category_id: data?.category?.id,
    product_name: data?.product?.name || ''
  };
};

export const useCustomFormik = ({
  data,
  id,
  submit,
  editorState,
  me,
}: {
  data: Partial<IPublication> | null;
  id: string;
  submit: (props: { id: number; data: Partial<IEditPublication> }) => void;
  editorState: any;
  me: any
}) => {
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  const submitHandler = useCallback(
    (values: Partial<IPublication> & { category_id?: number, product_name?: string }) => {
      let text = '';
      const editorContent = editorState.getCurrentContent();
      if (editorContent.hasText()) {
        text = stateToHTML(editorContent);
      }
      values.text = text;
      if (!isAdmin){
        values.type = undefined
        values.favorite = undefined
      }
      

      submit({ id: +id, data: values });
    },
    [id, submit, editorState]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(data),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
