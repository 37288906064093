import axios from 'axios';
import { getOrderStatisticsUrl, getProductStatisticsUrl } from '../constants';

export const getOrderStatistics = (
  date_from?: string,
  date_to?: string,
  company_id?: number | string,
  accord_status?: string,
  csv?: boolean
) => {
  return axios.get(getOrderStatisticsUrl(), {
    params: {
      company_id,
      date_from,
      date_to,
      csv,
      accord_status: accord_status && accord_status !== '0' ? accord_status : undefined,
    },
  });
};

export const getProductStatistics = (
  date_from?: string,
  date_to?: string,
  company_id?: number,
  sort_for?: string,
  sort_type?: string
) => {
  return axios.get(getProductStatisticsUrl(), {
    params: {
      company_id,
      date_from,
      date_to,
      sort_for,
      sort_type,
    },
  });
};
