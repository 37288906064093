import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import { LayoutContextConsumer } from '../LayoutContext';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import DzIcon from './DzIcon';
import VkIcon from './VkIcon';
import TgIcon from './TgIcon';
import { IconButton } from '@material-ui/core';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dialogContentType: '',
    };
  }

  handleDialogOpen = contentType => {
    this.setState({
      dialogContentType: contentType,
      openDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  render() {
    const today = new Date().getFullYear();
    const { openDialog, dialogContentType } = this.state;
    return (
      <LayoutContextConsumer>
        {({ footer: { show } }) =>
          show && (
            <div className='kt-footer-box'>
              <div>
                <div>
                  <span className='kt-footer-span'>
                    &copy;{today.toString()}&nbsp;ООО «Айтисолюшн»
                  </span>
                  <br />
                  <span className='kt-footer-span'>
                    Поддержка по телефону&nbsp;
                    <a href='tel:+74951658875' rel='noopener noreferrer' className='kt-link'>
                      +7 495 165-88-75
                    </a>{' '}
                    и почте&nbsp;
                    <a
                      href='mailto:info@ruherb.pro'
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      info@ruherb.pro
                    </a>
                  </span>
                  <br />
                  <span className='kt-footer-span'>
                    <a
                      rel='noopener noreferrer'
                      className='kt-link'
                      onClick={() => this.handleDialogOpen('user_agreement')}
                    >
                      Пользовательское соглашение
                    </a>
                    &nbsp;и&nbsp;
                    <a
                      onClick={() => this.handleDialogOpen('privacy_policy')}
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      политика конфиденциальности
                    </a>
                  </span>
                </div>
              </div>
              
              <div className='iconContent' >
                <IconButton
                 onClick={() => (window.location.href = 'https://dzen.ru/ruherbpro')} 
                  size='small'
                >
                 <DzIcon />
                </IconButton>

                <IconButton
                  onClick={() => (window.location.href = 'https://vk.com/ruherbpro')}
                  size='small'
                >
                  <VkIcon />
                </IconButton>

                <IconButton
                  onClick={() => (window.location.href = 'https://t.me/ruherbpro')}
                  size='small'
                >
                  <TgIcon />
                </IconButton>
              </div>
              {/*<a*/}
              {/*  href="http://keenthemes.com/metronic"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*  className="kt-footer__menu-link kt-link"*/}
              {/*>*/}
              {/*  About*/}
              {/*</a>*/}
              {/*<a*/}
              {/*  href="http://keenthemes.com/metronic"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*  className="kt-footer__menu-link kt-link"*/}
              {/*>*/}
              {/*  Team*/}
              {/*</a>*/}
              {/*<a*/}
              {/*  href="http://keenthemes.com/metronic"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*  className="kt-footer__menu-link kt-link"*/}
              {/*>*/}
              {/*  Contact*/}
              {/*</a>*/}
              {/*</div>*/}
              <>
                <TermDialog
                  isOpen={openDialog}
                  handleClose={this.handleDialogClose}
                  contentType={dialogContentType}
                />
              </>
            </div>
          )
        }
      </LayoutContextConsumer>
    );
  }
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
