import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { TAuthTabs } from '.';
import { useFormikEnterConfirmCode } from '../hooks/useFormikEnterConfirmCode';
import { useAxiosPostCallback } from '../../../hooks/useAxiosGet';
import { useShowErrors } from '../../../hooks/useShowErrors';
import { actions as authActions } from '../../../store/ducks/auth.duck';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  countryData: any;
  phone: string;
}

export const EnterConfirmCode: React.FC<IProps> = ({ changeAuthTab, countryData, phone }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [res, loading, err, makeRequest] = useAxiosPostCallback();

  const {
    handleBlur,
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormikEnterConfirmCode(countryData, phone, makeRequest);

  const { nextUrl } = useSelector(({ auth }: any) => auth);
  const { setNextUrl } = authActions;

  const history = useHistory();

  useEffect(() => {
    if (res) {
      dispatch(authActions.loginSuccess(res.data));
      nextUrl && history.push(nextUrl)
      dispatch(setNextUrl(null))

    }
  }, [res]);

  useShowErrors([err]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'ENTER_CONFIRM_CODE' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <div className='form-group'>
              <InputMask
                mask='9999'
                onBlur={handleBlur}
                value={values.code}
                maskPlaceholder={null}
                alwaysShowMask={false}
                onChange={(e: any) => {
                  setFieldValue('code', e.target.value || '');
                }}
              >
                {(inputProps: any) => (
                  <TextField
                    {...inputProps}
                    type='tel'
                    label={intl.formatMessage({
                      id: 'AUTH.INPUT.CODE',
                    })}
                    margin='normal'
                    className='kt-width-full'
                    name='code'
                    helperText={touched.code && errors.code}
                    error={Boolean(touched.code && errors.code)}
                    InputLabelProps={{
                      shrink: !!values.code,
                    }}
                    InputProps={{
                      inputMode: 'numeric',
                    }}
                  />
                )}
              </InputMask>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div className='kt-login__actions' style={{ marginRight: 30 }}>
              <button
                onClick={() => changeAuthTab('CheckInSystem')}
                type='button'
                className='btn btn-secondary btn-elevate kt-login__btn-secondary'
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
              </button>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader onPress={handleSubmit} disabled={loading} loading={loading}>
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
