import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  getDefaultKeyBinding,
  convertFromHTML,
  BlockMapBuilder,
} from 'draft-js';
import { makeStyles } from '@material-ui/styles';
import { Paper, Divider, Typography, TextField, Button } from '@material-ui/core';

import { EditorToolbar } from './components';
import { blockRenderMap } from './utils';
import { useFormatMessage } from '../../../hooks';

const useStyles = () =>
  makeStyles((theme: any) => ({
    root: {
      
    },
    linkDialog: {
      borderRadius: 4,
      backgroundColor: 'white',
      width: '50%',
      position: 'absolute',
      zIndex: 5,
      padding: '16px 24px',
    },
    linkDialogBg: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#00000055',
    },
    editorContainer: {
      padding: theme.spacing(2),
      '& .public-DraftEditorPlaceholder-root': {
        ...theme.typography.body2,
      },
      '& .public-DraftEditorPlaceholder-hasFocus': {
        display: 'none',
      },
      '& .public-DraftEditor-content': {
        '& p': {
          ...theme.typography.body1,
        },
        '& h1': {
          ...theme.typography.h1,
          fontSize: '2.5rem',
          fontWeight: 400,
        },
        '& h2': {
          ...theme.typography.h2,
          fontSize: '2rem',
          fontWeight: 400,
          textTransform: 'uppercase',
        },
        '& h3': {
          ...theme.typography.h3,
          fontSize: '1.5rem',
          fontWeight: 400,
        },
        // '& h4': {
        //   ...theme.typography.h4,
        // },
        // '& h5': {
        //   ...theme.typography.h5,
        // },
        // '& h6': {
        //   ...theme.typography.h6,
        // },
        // '& blockquote': {
        //   ...theme.typography.subtitle1,
        // },
        '& ul': {
          ...theme.typography.body1,
          marginLeft: theme.spacing(4),
        },
        '& ol': {
          ...theme.typography.body1,
          marginLeft: theme.spacing(4),
        },
        // '& pre': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.05)',
        //   fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        //   fontSize: 16,
        //   padding: 2,
        // },
        link: {
          color: '#3b5998',
          textDecoration: 'underline',
        },
      },
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    textAlignJustify: {
      textAlign: 'justify',
    },
  }));

const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const RichEditor = (props: any) => {
  const {
    placeholder,
    className,
    publicEditorState,
    editorState,
    setEditorState,
    style,
    disabled,
    showHeading,
    showCreateLink,
    ...rest
  } = props;

  const fm = useFormatMessage();
  const classes = useStyles()();
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [displayText, setDisplayText] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [selectedText, setSelectedText] = useState<string>('');
  const editorRef = useRef(null);

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleContainerClick = () => {
    if (editorRef) {
      // @ts-ignore
      editorRef.current.focus();
    }
  };

  const handleToolbarToggle = (type: string, value: any) => {
    switch (type) {
      case 'blockType': {
        if (['left', 'center', 'right', 'justify'].includes(value)) {
          const newContentState = Modifier.setBlockData(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            // @ts-ignore
            { 'text-align': value }
          );

          const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'change-block-data'
          );

          setEditorState(newEditorState);
          return;
        }

        setEditorState(RichUtils.toggleBlockType(editorState, value));
        break;
      }
      case 'inlineStyle': {
        setEditorState(RichUtils.toggleInlineStyle(editorState, value));
        break;
      }
      case 'createLink': {
        const sel = editorState.getSelection();
        const start = sel.getStartOffset();
        const stop = sel.getEndOffset();

        const anchor = sel.getAnchorKey();
        const text = editorState
          .getCurrentContent()
          .getBlockForKey(anchor)
          .getText()
          .slice(start, stop);
        setSelectedText(text);
        setDisplayText(text || '');
        setLinkDialogOpen(true);
        // const newContent = Modifier.insertText(
        //   editorState.getCurrentContent(),
        //   sel,
        //   'nice try, chump!'
        // );
        // const newState = EditorState.push(editorState, newContent, 'change-block-data');
        // setEditorState(newState);
        break;
      }
      case 'pasteLink': {
        if (!link && !displayText) return
        if (selectedText) {
          const contentState = editorState.getCurrentContent();

          const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', {
            url: link,
          });

          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
          });
          setEditorState(
            RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey)
          );
        } else {
          const htmlContent = convertFromHTML(
            `<a href='${link}'>${displayText}</a>`,
            undefined
          );
          const currentContent = editorState.getCurrentContent();
          const currentSelection = editorState.getSelection();
          const htmlContentMap = BlockMapBuilder.createFromArray(htmlContent.contentBlocks);
          const content = Modifier.replaceWithFragment(
            currentContent,
            currentSelection,
            htmlContentMap
          );
          const newEditorState = EditorState.push(editorState, content, 'insert-characters');

          setEditorState(newEditorState);
        }

        break;
      }
      default:
        break;
    }
  };

  const handleEditorChange = (editorStateProp: any) => {
    setEditorState(editorStateProp);
  };

  const handleKeyCommand = (command: any, editorStateProp: any) => {
    const newState = RichUtils.handleKeyCommand(editorStateProp, command);

    if (newState) {
      handleEditorChange(newState);
      return true;
    }

    return false;
  };

  const mapKeyToEditorCommand = (event: any) => {
    if (event.keyCode === 9) {
      const newEditorState = RichUtils.onTab(event, editorState, 4);

      if (newEditorState !== editorState) {
        handleEditorChange(newEditorState);
      }

      return;
    }

    return getDefaultKeyBinding(event);
  };

  function blockStyleFn(contentBlock: any) {
    const textAlign = contentBlock.getData().get('text-align');

    if (textAlign) {
      // @ts-ignore
      return classes[`textAlign${capitalize(textAlign)}`];
    }

    return '';
  }

  return (
    <Paper {...rest} className={clsx(classes.root, className)}>
      {linkDialogOpen && (
        <div className={classes.linkDialogBg}>
          <div className={classes.linkDialog}>
            <div>
              <Typography variant='h6'>Вставить ссылку</Typography>
            </div>
            <div>
              <TextField
                size='small'
                type='text'
                label='Текст'
                margin='normal'
                value={displayText}
                onChange={event => setDisplayText(event.target.value)}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                size='small'
                type='text'
                label='Ссылка'
                margin='normal'
                value={link}
                onChange={event => setLink(event.target.value)}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: 8 }}>
              <Button
                onClick={() => {
                  setDisplayText('');
                  setLink('');
                  setLinkDialogOpen(false);
                }}
                variant='outlined'
                color='primary'
              >
                {fm('CATEGORY.BUTTON.CANCEL')}
              </Button>
              <Button
                style={{ marginLeft: 16 }}
                onClick={() => {
                  setDisplayText('');
                  setLink('');
                  setLinkDialogOpen(false);
                  handleToolbarToggle('pasteLink', 'Salom');
                }}
                variant='contained'
                color='primary'
              >
                {fm('CATEGORY.TITLE.ADD')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {!disabled && (
        <>
          <EditorToolbar
            editorState={editorState}
            onToggle={handleToolbarToggle}
            showHeading={showHeading}
            showCreateLink={showCreateLink}
          />
          <Divider />
        </>
      )}
      <div
        className={clsx(classes.editorContainer, className?.editor)}
        style={style}
        onClick={handleContainerClick}
      >
        {
          // @ts-ignore
          <Editor
            blockRenderMap={blockRenderMap}
            blockStyleFn={blockStyleFn}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={handleEditorChange}
            placeholder={placeholder}
            ref={editorRef}
            spellCheck
          />
        }
      </div>
    </Paper>
  );
};

export default RichEditor;
