import React, { useState, useCallback, useEffect } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import Rating from './Rating';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { useStylesReviewsList } from '../hooks/useStyles';
import { useDefineUserRole } from '../../../../hooks';
import { useCreateReview } from '../hooks/useCreateReviewFormik';
import  { ButtonWithLoader} from '../../../../components/ui/Buttons';
import { ColorButton } from '../../../../components/other/Buttons';

interface IProps {
  productId: string | number;
  meAdd?: boolean;
  setOpen: (value: boolean)=> void
}

const ReviewsList: React.FC<IProps> = ({ productId, meAdd = false,setOpen }) => {
  const styles = useStylesReviewsList();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');

  const [fetchReviews, loading] = useGetReviews();
  const [createRevieFormik, loadingCreate, successCreate] = useCreateReview(productId, () => setOpen(false));
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  useEffect(() => {
    if (successCreate) {
      fetchReviews(productId, pageReviews.perPage, pageReviews.page);
    }
  }, [successCreate]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading || loadingCreate)
    return (
      <div className={styles.reviewContent}>
        <Preloader />
      </div>
    );

  return (
    <div className={styles.reviewContent}>
      {reviewsProduct && meAdd && (
        <Typography
        component='h5'
        variant='h5'
        color='textPrimary'
        style={{textAlign:'center'}}
      >
        Вы уже оставили отзыв
      </Typography>
      )}

      {(isBuyer || isAdmin) && !meAdd && (
        <form
          onSubmit={createRevieFormik.handleSubmit}
          className='kt-form'>
            <div className={styles.titleContent}>
              <Typography
                  component='h5'
                  variant='h5'
                  color='textPrimary'
                >
                  Проставьте рейтинг товару
              </Typography>

              <div className={styles.ratingContent}>
                <Rating
                  size='26px'
                  rating={createRevieFormik.values.rating}
                  onChange={rating => createRevieFormik.setFieldValue('rating', rating)}
                />
              </div>
            </div>

            {!!createRevieFormik.errors.rating && (
              <Typography
                component='h6'
                variant='subtitle1'
                color='error'
                style={{ textAlign: 'center' }}
              >
                {createRevieFormik.errors.rating}
              </Typography>
            )}

            <Typography variant='h6' 
              style={{
                fontSize:'14px',
                fontWeight:'600', 
                color:'black',
                marginTop: '20px'}}>
                Напишите ваш отзыв  
            </Typography>

            <TextField
              type='text'
              label="Отзыв"
              margin='normal'
              multiline
              rows={15}
              variant='outlined'
              name='text'
              value={createRevieFormik.values.text}
              onBlur={createRevieFormik.handleBlur}
              onChange={createRevieFormik.handleChange}
              helperText={createRevieFormik.touched.text && createRevieFormik.errors.text}
              error={Boolean(createRevieFormik.touched.text && createRevieFormik.errors.text)}
            />

            <div style={{display:'flex',flexDirection: 'row',justifyContent:'space-between'}}>
            <ColorButton
              color='#fff'
              style={{width:'45%', color: '#002626',border: '2px solid #002626',}}
              onClick={()=> setOpen(false)}
              >
              Отмена
            </ColorButton>

            <ButtonWithLoader style={{width: '45%'}}  disabled={loadingCreate} loading={loadingCreate}>
              Отправить отзыв
            </ButtonWithLoader>
          </div>
        </form>
      )}
    </div>
  );
};

export default ReviewsList;
