import axios from 'axios';
import {
  addProuctToSupplyUrl,
  createSupplyUrl,
  getAccordSupplyUrl,
  getListSupplyUrl,
} from '../constants';

export function createSupply({
  id,
  status,
  comment,
  shipmentDate,
}: {
  id: number;
  status: string;
  comment: string;
  shipmentDate: string | null;
}) {
  const formData = new FormData();
  formData.append('id', String(id));
  formData.append('status', status);
  formData.append('comment', comment);
  shipmentDate && formData.append('shipment_date', shipmentDate);
  return axios.post(createSupplyUrl(), formData);
}

export function fetchSupply() {
  return axios.get(getListSupplyUrl());
}

export function fetchAccordSupply() {
  return axios.get(getAccordSupplyUrl());
}

export function addProduct({ product_id, count }: { product_id: number; count: number }) {
  const formData = new FormData();
  formData.append('product_id', String(product_id));
  formData.append('count', String(count));
  return axios.post(addProuctToSupplyUrl(), formData);
}

export const getAccordDocsList = () => axios.get('/api/accord/docs/list');

export const getAccordSupplyCart = () => axios.get('/api/accord_supply/cart');
