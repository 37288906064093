import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../../constants/colors';

export const ReviewsStyles = makeStyles((theme) =>
  createStyles({
    reviews: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column-reverse',
        padding: '0 16px',
      },
    },
    reviewsList: {
      width: '100%',
      padding: '10px 20px',
      [theme.breakpoints.down(500)]: {
        marginTop: 24,
        width: '100%',
        paddingRight: 0,
      },
    },
    statistics: {
      width: '100%',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
      background: '#f2f7f7',
      padding: '0 10px'
    },
    statisticsHeader: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.primary.lightGrey}`,

      marginBottom: 22,
      [theme.breakpoints.down(500)]: {
        alignItems: 'center',

        '& p': {
          fontSize: 23,
        },
      },
    },
    headerTitle: {
      fontSize: 20,
      padding: 0,
      margin: '15px 0',
      fontWeight: 800,
      [theme.breakpoints.down(500)]: {
        fontSize: '18px !important',
        margin: '24px 0 !important',
        padding: '0 16px',
      },
    },
    avatar: {
      width: 50,
      height: 50,

      marginRight: 8,

      '& img': {
        borderRadius: '50%',
        width: '100%',
        objectFit: 'contain',
        height: '100%',
      },
    },
    feedButton: {
      margin: '16px 0',
      width: '50%'
    },
    statisticsList: {
      marginBottom: 8,
    },
    statisticsItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      whiteSpace: 'nowrap',

      '& .progress': {
        /* Primary colors/Green */
        background: '#d8d8d8',
        borderRadius: 12,
        height: 5,
        flex: 1,
        overflow: 'hidden',
        margin: '0 16px',
        '& span': {
          display: 'inline-block',
          height: '100%',
          background: '#78dc78',
        },
      },
      // [theme.breakpoints.down(500)]: {
      //   '& p:first-child': {
      //     marginRight: `16px !important`,
      //     color: 'red',
      //   },
      //   '& p:last-child': {
      //     marginLeft: `16px !important`,
      //     color: 'red',
      //   },
      // },
    },
    reviewsText: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column-reverse',
        padding: '0 16px',
      },
    },
    subButtonContent: {
      paddingTop: 16,
      borderTop: `1px solid ${colors.primary.lightGrey}`,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textContent: {
      display:'flex',
      justifyContent:'center',
      height:25,
      alignItems: 'flex-end'
    },
    retingContent: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 'auto',
      justifyContent: 'space-between',
      width: '100%',
      alignItems:'center'
    },
    subButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems: 'center',
      padding: '0 10px',
      marginTop:'20px',
      [theme.breakpoints.down(400)]: {
        flexDirection: 'column',
        gap: 10,
      },
    },
    button: {
      width:'47%',
      [theme.breakpoints.down(400)]: {
        width: '100% !important',
      },
    },
  })
);
