import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as userActions } from '../../../store/ducks/users.duck';
import { IAppState } from '../../../store/rootDuck';
import { FilterAccordion } from './FilterAccordion';

export const UsersFilter = () => {
  const dspch = useDispatch();

  const { userAttrStored, filter } = useSelector(
    (store: IAppState) => ({
      userAttrStored: store.users.userAttr,
      filter: store.users.filter,
    }),
    shallowEqual
  );

  const handleChange = useCallback(
    (type: 'countries' | 'languages', e: any) => {
      const value = filter[type];

      if (e.target.checked) {
        value.push(e.target.name);
      } else {
        const index = value.indexOf(e.target.name);
        if (index > -1) {
          value.splice(index, 1);
        }
      }

      dspch(userActions.setFilter({ [type]: value }));
    },
    [filter]
  );

  const clear = useCallback(
    (type: 'countries' | 'languages') => {
      dspch(userActions.setFilter({ [type]: [] }));
    },
    [filter]
  );

  if (!userAttrStored) return <></>;

  return (
    <Col>
      <Row>
        <FilterAccordion
          type='countries'
          name='Страна'
          filter={filter}
          userAttrStored={userAttrStored.country}
          handleChange={handleChange}
          clear={clear}
        />
        <FilterAccordion
          type='languages'
          name='Язык'
          filter={filter}
          userAttrStored={userAttrStored.languages}
          handleChange={handleChange}
          clear={clear}
        />
      </Row>
    </Col>
  );
};
