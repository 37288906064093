import React from 'react';

const DzIcon: React.FC = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill-rule="nonzero" fill="none">
            <circle fill="#E3EDF3" cx="16" cy="16" r="16"/>
            <path d="M17.254 16.754C16.232 17.807 16.158 19.12 16.07 22c2.683 0 4.536-.01 5.492-.938.929-.956.938-2.897.938-5.492-2.879.092-4.193.162-5.246 1.184zM9.5 15.57c0 2.595.01 4.536.938 5.492.956.929 2.809.938 5.492.938-.092-2.879-.162-4.193-1.184-5.246-1.054-1.022-2.367-1.096-5.246-1.184zM15.93 9c-2.679 0-4.536.01-5.492.938-.929.956-.938 2.897-.938 5.492 2.879-.092 4.192-.162 5.246-1.184C15.768 13.192 15.842 11.88 15.93 9zm1.324 5.246C16.232 13.192 16.158 11.88 16.07 9c2.683 0 4.536.01 5.492.938.929.956.938 2.897.938 5.492-2.879-.092-4.193-.162-5.246-1.184z" fill="#005A5A"/>
            <path d="M22.5 15.57v-.14c-2.879-.092-4.193-.162-5.246-1.184C16.232 13.192 16.158 11.88 16.07 9h-.14c-.092 2.879-.162 4.192-1.184 5.246-1.054 1.022-2.367 1.096-5.246 1.184v.14c2.879.092 4.192.162 5.246 1.184 1.022 1.053 1.096 2.367 1.184 5.246h.14c.093-2.879.162-4.193 1.184-5.246 1.053-1.022 2.367-1.096 5.246-1.184z" fill="#E3EDF3"/>
        </g>
      </svg>
    </>
  );
};

export default DzIcon;
