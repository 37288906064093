import React from 'react';

interface IProps {
  size?: string;
}

const VkIcon: React.FC<IProps> = ({ size='24' }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill-rule="nonzero" fill="none">
            <circle fill="#E3EDF3" cx="16" cy="16" r="16"/>
            <path d="M20.617 16.601c-.226-.286-.162-.413 0-.668.003-.003 1.871-2.585 2.064-3.46l.001-.001c.096-.32 0-.554-.462-.554h-1.531c-.39 0-.57.201-.666.426 0 0-.78 1.866-1.882 3.075-.355.35-.52.462-.714.462-.096 0-.245-.112-.245-.431v-2.978c0-.383-.109-.554-.431-.554h-2.407c-.244 0-.39.179-.39.345 0 .363.551.446.609 1.467v2.215c0 .485-.088.574-.284.574-.52 0-1.783-1.873-2.531-4.016-.152-.416-.3-.584-.692-.584H9.525c-.437 0-.525.2-.525.426 0 .398.52 2.376 2.42 4.99 1.265 1.783 3.048 2.75 4.67 2.75.975 0 1.094-.215 1.094-.584 0-1.705-.088-1.866.4-1.866.226 0 .616.112 1.526.973 1.04 1.02 1.21 1.477 1.793 1.477h1.53c.437 0 .658-.215.53-.639-.29-.89-2.257-2.723-2.346-2.845z" fill="#005A5A"/>
        </g>
      </svg>
    </>
  );
};

export default VkIcon;
