import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';


import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as blogActions } from '../../../store/ducks/blog.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { PublicationsList } from './components';
import TablePaginator from '../../../components/TablePaginator/TablePaginator';

import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { UserRoles } from '../../../interfaces/user';
import { IPublication } from './interfaces';
import { useFormatMessage } from '../../../hooks';

export const useStyles = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    addBtn: {
      marginBottom: 10,
    },
    title: {
      fontStyle: 'bold',
      fontWeight: 400,
      color: '#000',
      paddingLeft: 120,
      paddingBottom: 20,
      fontSize: 30,

      [theme.breakpoints.up('xl')]: {
        paddingLeft: 120,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        paddingLeft: 124,
      },
    }
  })
);

const AllPublicationsPage: React.FC<TPropsFromRedux> = ({
  me,
  loadingMe,
  fetchMe,
  clearMe,

  page,
  perPage,
  total,

  loading,
  publications,
  getPublications,
  clearPublications,

  fetchAprowedPublication,
  clearAprowedPublication,

  deleteLoading,
  deleteSuccess,
  deleteError,
  deletePublication,
  clearDeletePublication,
}) => {
  const intl = useIntl();
  const fm = useFormatMessage();
  const classes = useStyles()

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [width, setWidth] = useState<number | undefined>();

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    // setAlertOpen(true);
  }, []);

  const [currentPublicatoins, setCurrentPublications] = useState<IPublication[] | null>(null);

  useEffect(() => {
    setCurrentPublications(publications);
  }, [publications]);

  setLayoutSubheader({ title: '' });
  setLayoutFooter({ show: true });


  const isAdmin = useMemo(() => me?.roles.includes(UserRoles.ROLE_ADMIN), [me]);
  const isVendor = useMemo(() => me?.roles.includes(UserRoles.ROLE_VENDOR), [me]);
  const isBuyer = useMemo(() => me?.roles.includes(UserRoles.ROLE_BUYER), [me]);
  const isS = useMemo(() => me?.roles.includes(UserRoles.ROLE_USER), [me]);

  useEffect(()=> {
    fetchMe();
  },[])
  

  useEffect(() => {
    if (isAdmin) {
      getPublications({ page: 1, perPage: 20 });
    }else {
      // getPublications({ page: 1, perPage: 20, favorite: true, status: 'approved' });
      fetchAprowedPublication({page: 1, perPage: 20, status: 'approved'})
    }
    return () => {
      clearMe();
      clearPublications();
    };
  }, [isAdmin]);
  

  // useCrudSnackbar({
  //   success: deleteSuccess,
  //   error: deleteError,
  //   clear: clearDeletePublication,
  //   successMessage: 'all',
  //   // `${intl.formatMessage({ id: 'SUCCESS' })}`,
  //   errorMessage: `${intl.formatMessage({ id: 'PUBLICATIONS.DELETE.ERROR' })}: ${deleteError}`,
  //   afterSuccess: () => {
  //     getPublications({ page, perPage });
  //   },
  //   afterSuccessOrError: () => {
  //     setAlertOpen(false);
  //   },
  // });

  const updateWidth = () => setWidth(window.innerWidth);

  useEffect(() => {
    updateWidth();
  }, [updateWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  }, []);

  if (loading || deleteLoading || loadingMe || !currentPublicatoins) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteId && deletePublication({ id: deleteId })}
      />
      <Typography 
        variant='h6' 
        className={classes.title}>
        Блог
      </Typography>

      <Row style={{padding: '0 100px'}}>
        <Col>
          <PublicationsList
            deletePost={handleDeleteDialog}
            meId={me?.id || 0}
            companyId={me?.company?.id || undefined}
            publications={currentPublicatoins}
            isAdmin={isAdmin}
            width={width}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              alignItems: 'center',
            }}
          >
            <TablePaginator
              page={page}
              realPerPage={currentPublicatoins.length}
              perPage={perPage}
              total={total}
              fetchRows={getPublications}
              label={intl.formatMessage({ id: 'PUBLICATIONS.TABLE.PERPAGE' })}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    page: state.blog.page,
    perPage: state.blog.per_page,
    total: state.blog.total,

    loading: state.blog.publicationsLoading,
    success: state.blog.publicationsSuccess,
    error: state.blog.publicationsError,
    publications: state.blog.publications,

    deleteLoading: state.blog.deletePublicationLoading,
    deleteSuccess: state.blog.deletePublicationSuccess,
    deleteError: state.blog.deletePublicationError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    getPublications: blogActions.publicationsRequest,
    clearPublications: blogActions.clearFetchPublications,

    deletePublication: blogActions.deletePublicationRequest,
    clearDeletePublication: blogActions.clearDeletePublication,
    fetchAprowedPublication: blogActions.AprowedpublicationsRequest,
    clearAprowedPublication: blogActions.clearFetchAprowedPublications
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AllPublicationsPage);