import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AllPublicationsPage from './AllPublications';
import MyPublicationsPage from './MyPublications';
import CreatePublicationPage from './CreatePublication';
import EditPublicationPage from './EditPublication';
import ViewPublicationPage from './ViewPublication';
import { IAppState } from '../../../store/rootDuck';
import { PrivateRoute } from '../../../router/Routes';

export default function BlogPage() {
  const { isAuthorized } = useSelector(({ auth }: IAppState) => ({
    isAuthorized: auth.user != null,
  }));

  return (
    <Switch>
      <Route path='/blog/all' exact component={AllPublicationsPage} />
      <PrivateRoute
        path='/blog/my'
        exact
        component={MyPublicationsPage}
        isAuthorized={isAuthorized}
      />
      <PrivateRoute
        path='/blog/create'
        exact
        component={CreatePublicationPage}
        isAuthorized={isAuthorized}
      />
      <PrivateRoute
        path='/blog/edit/:id'
        exact
        component={EditPublicationPage}
        isAuthorized={isAuthorized}
      />
      <Route path='/blog/view/:id' exact component={ViewPublicationPage} />
    </Switch>
  );
}
