import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import  { IconButton,Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import EditeIcon from './EditIcon';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { ReviewsStyles } from '../hooks/ReviewsStyles';
import { useDefineUserRole , useFormatMessage } from '../../../../hooks';
import Rating from './Rating';
import { API_DOMAIN } from '../../../../constants';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { IProduct } from '../../../../interfaces/product';
import { TextType, Text } from '../../../../components/ui/Text';
import { colors } from '../../../../constants/colors';
import { ColorButton } from '../../../../components/other/Buttons';
import { Modal } from '../../../../components/other/Modals';
import ReviewsList from './ReviewsList';


interface IProps {
  meAdd?: boolean;
  product: IProduct;
  name: string;
}

const ReviewComponent: React.FC<IProps> = ({ meAdd = false, product, name }) => {
  const classes = ReviewsStyles();
  const intl = useIntl();
  const fm = useFormatMessage();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const history = useHistory();
  const productId = useMemo(() => product.id, [product]);
  const [open, setOpen] = useState(false);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );

  if (loading) return <Preloader />;

  return (
      <div className={classes.reviews}>
        <div className={classes.statistics}>
          <div className={classes.statisticsHeader}>
            <Rating rating={reviewsProduct?.rating} />

            <Text style={{ fontSize: 17, marginBottom: -10 }} type={TextType.boldH3}>
              {reviewsProduct?.rating || 0}/5
            </Text>
          </div>

          {reviewsProduct && (
            <div className={classes.statisticsList}>
              {Array(5)
                .fill('')
                .map((_, index) => {
                  const length =
                    reviewsProduct.reviews.filter((item) => item.rating === 5 - index).length || 0;
                  const percent = (length * 100) / reviewsProduct.reviews.length || 0;

                  return (
                    <div key={index} className={classes.statisticsItem}>
                      <Text type={TextType.body2}>{5 - index} {index === 0 ? 'звезд' : null}{index === 4 ? 'звезда': null}{index !==0 && index !==4 ? 'звезды' : null}</Text>
                      <div className="progress">
                        <span style={{ width: `${percent}%` }} />
                      </div>
                      <Text type={TextType.semiboldSubtitle2}>{length} </Text>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className={classes.feedButton}>
          {(isAdmin || me?.id) && (
            <ColorButton
            color='#78dc78'
            style={{width:'100%', color: 'white'}}
            onClick={()=> setOpen(true)}>
            Написать отзыв
            </ColorButton>
          )}
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          content={
          <ReviewsList 
              productId={product?.id || ''} 
              meAdd={meAdd}
              setOpen={setOpen}
            />
          }
        />  
      </div>

  );
};

export default ReviewComponent;