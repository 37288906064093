import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesProducView = makeStyles(theme =>
  createStyles({
    editor: {
      boxShadow: 'initial !important',
      padding: '0 !important',
      marginTop: 40,
      color: '#002626',
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    container: {
      padding: '0',

      [theme.breakpoints.up('md')]: {
        padding: '0 22px',
      },
    },
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '55px',
      [theme.breakpoints.down('md')]: {
        padding: '15px',
      },
    },
    leftCol: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
      alignItems:'center',
    },
    rightCol: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: '38%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 2
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      marginTop: 25,
      marginBottom: 30,
    },
    name: {
      fontSize: 28,
      color: '#002626',
      fontWeight: 'bold',
      margin: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    addToCart: {
      width: 230,
      height: 52,
      fontSize: 20,
      textTransform: 'none',
      fontWeight: 300,
      marginTop: 10,
      marginBottom: 10
    },
    parameters: {
      fontSize: 22,
      fontWeight: 'bold',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
      },
    },
    price: {
      color: '#005a5a',
      fontSize: 34,
      fontWeight: 600,
      margin: 0,
    },
    parameterName: {
      color: '#757575',
      fontSize: 17,
      fontWeight: 400,
      margin: 0,
      paddingTop: 0,
      marginTop: -5
    },
    parameterValue: {
      fontSize: 20,
      fontWeight: 600,
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
    whatsAppButton: {
      width: '100%',
      height: 42,
      padding: '12px 20px',
      borderRadius: 4,
      backgroundColor: '#25d366',
      fontSize: 14,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#fff',
      display: 'inline-block',
      marginTop: 20,

      '&:hover': {
        color: '#fff',
      },

      [theme.breakpoints.up('md')]: {
        width: 215,
      },
    },
    listItem: {
      marginBottom: 15,
    },
    listValue: {
      fontSize: 17,
      fontWeight: 400,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#757575',
      marginBottom: 0,
      marginTop: -5,
    },
    listKey: {
      marginBottom: 0,
      fontSize: 17,
      color: '#002626',
      fontWeight: 'bold'
    },
    companyCard: {
      padding: '15px 25px',
      borderRadius: '4px',
      backgroundColor: '#f2f7f7',
      marginTop: 30,
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      },
    },
    infoWrapper: {
      marginTop: '35%',

      [theme.breakpoints.down('md')]: {
        marginTop: 10
      },
    },
    reviewContent: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    reviewBtn: {
      border: '1px solid #ebebeb',
      padding: '10px 20px',
      margin: '40px 0',
    },
    reviewContainer: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    reviewsContent: {
      display: 'flex',
      flexWrap: 'wrap-reverse',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        padding: '10px 0',
      },
      width: '100%'
    }
  })
);
