import React from 'react';

interface IProps {
  size?: string;
}

const TgIcon: React.FC<IProps> = ({ size='24' }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill-rule="nonzero" fill="none">
            <circle fill="#E3EDF3" cx="16" cy="16" r="16"/>
            <path d="M20.892 11.096s1.202-.47 1.102.67c-.033.469-.334 2.11-.568 3.885l-.801 5.259s-.067.77-.669.904c-.601.134-1.503-.469-1.67-.603-.134-.1-2.505-1.608-3.34-2.345-.234-.2-.502-.602.033-1.071l3.508-3.35c.4-.402.801-1.34-.869-.2l-4.677 3.181s-.534.335-1.536.034l-2.172-.67s-.802-.503.568-1.005c3.34-1.574 7.45-3.182 11.09-4.69z" fill="#005A5A"/>
        </g>
      </svg>
    </>
  );
};

export default TgIcon;
