import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useURLSearchParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const deleteParam = useCallback(
    (name: string) => {
      query.delete(name);
      history.replace({ pathname, search: query.toString() });
    },
    [query, history, pathname]
  );

  const objectParams = useMemo(() => Object.fromEntries(query), [query]);

  const setParams = useCallback(
    (name: string, value: string) => {
      query.set(name, value);
      history.replace({ pathname, search: query.toString() });
    },
    [pathname, query, history, pathname]
  );

  const clearAllParam = useCallback(
    (valuesSkip?: string[]) => {
      const values: string[] = [];
      query.forEach((key, value) => {
        if (valuesSkip && !valuesSkip.includes(value)) {
          values.push(value);
        }
      });
      values.forEach(i => {
        deleteParam(i);
      });
    },
    [query, history, pathname, deleteParam]
  );

  return { setParams, query, objectParams, deleteParam, clearAllParam };
};
