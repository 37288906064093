import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { countLettersInString } from '../../../utils/utils';

export const useFormikCheckInSystem = (
  initEmail: string,
  initPhone: string,
  makeRequest: any,
  tab: number,
  countryData: any
) => {
  const intl = useIntl();

  const validPhoneLength = useMemo(() => {
    if (!countryData) return null;
    return countLettersInString(countryData.mask, '*');
  }, [countryData]);

  const validationSchema = useMemo(() => {
    let shape = null;
    if (tab === 0) {
      shape = {
        email: Yup.string().required(
          intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })
        ),
      };
    } else {
      shape = {
        phone: Yup.string()
          .test('len', intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' }), val => {
            const valLengthWithoutDashes = val
              ?.replace(`+`, '')
              .replace(/-|_/g, '')
              .replace(/ /g, '')
              .replace(/[{()}]/g, '').length;
            return valLengthWithoutDashes === 10;
          })
          .required(intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' })),
      };
    }
    return Yup.object().shape(shape);
  }, [tab, validPhoneLength, intl]);

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: false,
    initialValues: {
      email: initEmail,
      phone: initPhone.slice(2).replaceAll(' ', ''),
    },
    validationSchema,
    onSubmit: submitValues => {
      makeRequest(
        { ...submitValues, phone: (countryData.code + submitValues.phone).replace(/\s/g, '') },
        tab
      );
    },
  });

  // clear phone value when country changed
  // useEffect(() => {
  //   formik.setFieldValue('phone', '');
  // }, [countryData]);

  return formik;
};
