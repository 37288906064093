export const colors = {
  complementary: {
    green: 'rgba(124, 177, 66, 0.15)',
    background: '#FBFAF8',
    gray: '#484848',
    gradient: 'linear-gradient(290.46deg, #FBFAF8 0%, #FFFFFF 100%)',
  },
  primary: {
    green: '#7CB142',
    greenHover: '#70A13B',
    yellow: '#FFD647',
    lightPink: '#F9E7E7',
    lightPinkHover: '#E8D8D8',
    lightGrey: '#DED6D6',
    lightSmokyGrey: '#FBFAF8',
    lightSmokyGreyHover: '#DEDDDB',

    black: '#1A1A1A',
    white: '#FFFFFF',
    dark: '#484848',
    border: '#e8f1e0',
    pink: '#FFB8BA',
    red: '#CD0063',
    redHover: '#BB4406',
  },
};
