import { useCallback, useEffect, useState } from 'react';
import { makeAxiosGetRequest, makeAxiosPostRequest } from '../utils/utils';

export const useAxiosGetCallback = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const makeRequest = useCallback((url: string) => {
    makeAxiosGetRequest(url, setData, setLoading, setErr);
  }, []);

  return [data, loading, err, makeRequest];
};

export const useAxiosGet = (url: string) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  useEffect(() => {
    makeAxiosGetRequest(url, setData, setLoading, setErr);
  }, [url]);

  return [data, loading, err];
};

export const useAxiosPostCallback = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const makeRequest = useCallback((url: string, data?: any) => {
    makeAxiosPostRequest(url, data, setData, setLoading, setErr);
  }, []);

  return [data, loading, err, makeRequest];
};
