/* eslint-disable no-plusplus, prefer-const */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, ButtonGroup, Card } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import moment from 'moment';

import { ImageGallery } from './components';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { UserRoles } from '../../../interfaces/user';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { thousands } from '../../../utils/utils';
import RichEditor from '../../../components/formComponents/RichEditor';
import { useStylesProducView } from './hooks/useStylesProductView';
import ReviewComponent from './components/ReviewComponent';
import ReviewsTextComponent from './components/ReviewsTextComponent';

import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';

const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  isAuthorized,
  guestCart,
  cart,
  isAdmin,
  me,
  loadingMe,
  editLoading,
  cartCompanyId,
  companyGuestCart,
  countProductsGuest,
  addProductLoading,
  fetch,
  clearProduct,
  setCountGuestCart,
  setProductCount,
  setProductCountLoading,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
  meAdd,
  clearAddProduct,
  addProductError,
  addProductSuccess,
  byIdError,
  clearById,
  addProductSubscribes,
  delProductSubscribes,
  editLoadingSubscribes,
  editSubscribesSuccess,
  editSubscribesError,
  delLoadingSubscribes,
  delSubscribesSuccess,
  delSubscribesError,
  byIdSuccess,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const fm = useFormatMessage();
  const history = useHistory();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);
  const [alertStockOver, setAlertStockOver] = useState(false);
  const [isAlertSubscribes, setAlertSubscribes] = useState(false);
  const [activeSubscribes, setActiveSubscribes] = useState<boolean | undefined>(
    product?.is_subscribe
  );
  // const [createChatFetch, loadingCreated, createChat] = useCreateChat();

  setLayoutSubheader({
    title: product?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
  });

  setLayoutFooter({ show: true });

  useEffect(() => {
    // if (state?.type === 'view_from_catalog') {
    //   fetch({ id: Number(id) });
    // }
    // if (state?.type === 'view_from_favorite') {
    //   fetch({ id: Number(id) });
    // }
    fetch({ id: Number(id) });
    return () => {
      clearProduct();
    };
  }, [id]);

  useEffect(() => {
    if (byIdSuccess) {
      setActiveSubscribes(product?.is_subscribe);
    }
  }, [byIdSuccess]);

  const descriptionHtml = useMemo(() => {
    if (product?.description) {
      return EditorState.createWithContent(stateFromHTML(product.description));
    }
    return EditorState.createEmpty();
  }, [product]);

  // handle add to cart
  // const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useCrudSnackbar({
    success: addProductSuccess,
    error: addProductError,
    successMessage: intl.formatMessage({ id: 'CART.ADD_PRODUCT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${addProductError}`,
    afterSuccessOrError: () => {
      clearAddProduct();
    },
  });

  useCrudSnackbar({
    success: false,
    error: byIdError,
    successMessage: '',
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${byIdError}`,
    afterSuccessOrError: () => {
      clearById();
      history.goBack();
    },
  });
  const characteristics = useMemo(() => {
    if (product) {
      const result: { [key: string]: any } = {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.CATEGORY' })]:
          product.category?.name || intl.formatMessage({ id: 'COMMON.NO_DATA' }),
      };

      product.parameter_values?.forEach(item => {
        if (item.parameter_id && item.parameter_name) {
          // запрос не возвращает название параметра
          result[item.parameter_name] = item.value;
        }
      });
      return result;
    }
    return {};
  }, [product]);

  const isCompanyInfo = useMemo(() => {
    return (
      product?.company?.brand ||
      // product.company?.address ||
      product?.company?.phone_number ||
      product?.company?.site
      // || product.company?.working_hours
    );
  }, [product?.company]);

  const companyInfo: { [key: string]: any } | null = isCompanyInfo
    ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })]: product?.company?.brand || null,
        // Адрес: product.company?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.SITE' })]: product?.company?.site || null,
        // 'Часы работы': product.company?.working_hours
        //   ? product.company?.working_hours.replace(/(\r\n|\n|\r)/gm, '<br>')
        //   : null,
      }
    : null;

  let productCount = useMemo(() => {
    if (!isAuthorized && product && guestCart) {
      const item = guestCart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    } else if (Boolean(isAuthorized) && product && cart) {
      const item = cart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return null;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  // let productStockQuantity = useMemo(() => {
  //   if (!isAuthorized && product && guestCart) {
  //     const item = guestCart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item?.product?.stock_quantity;
  //     }
  //   } else if (Boolean(isAuthorized) && product && cart) {
  //     const item = cart.items.find(item => item.product.id === product.id);
  //     if (item) {
  //       return item?.product?.stock_quantity;
  //     }
  //   }
  //   return null;
  // }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const handleCartDialog = useCallback(
    item => {
      const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
      if (isAuthorized) {
        addProductToCart({
          product_id: item.id ? item?.id : 0,
          count: 1,
        });
      } else {
        const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
        if (!stock || countProduct === stock || countProduct > stock) {
          setAlertStockOver(true);
        } else {
          setProductGuestCart({ data: item, type: countProductsGuest === 0 ? 'new' : 'cart' });
        }
      }
    },
    [
      addProductToCart,
      cartCompanyId,
      companyGuestCart,
      setProductGuestCart,
      guestCart,
      isBuyer,
      me,
    ]
  );

  const handleAddSubscribes = useCallback(
    (productId: number) => {
      if (me && me.email) {
        activeSubscribes
          ? delProductSubscribes({ productId, subscribeId: 0 })
          : addProductSubscribes({ productId, email: me.email });
      } else {
        setAlertSubscribes(true);
      }
    },
    [me, activeSubscribes]
  );

  useCrudSnackbar({
    success: editSubscribesSuccess,
    error: editSubscribesError,
    successMessage: intl.formatMessage({ id: 'CART.PRODUCTS.SUBSCRIBES.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editSubscribesError}`,
    afterSuccess: () => {
      setActiveSubscribes(!activeSubscribes);
    },
  });
  useCrudSnackbar({
    success: delSubscribesSuccess,
    error: delSubscribesError,
    successMessage: intl.formatMessage({ id: 'CART.PRODUCTS.DEL.SUBSCRIBES.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delSubscribesError}`,
    afterSuccess: () => {
      setActiveSubscribes(!activeSubscribes);
    },
  });

  // const addCartProductAction = useCallback(() => {
  //   if (!addProductLoading && cartProductId) {
  //     addProductToCart({
  //       product_id: cartProductId.id!,
  //       count: 1,
  //       newCart: true,
  //     });
  //   }
  // }, [cartProductId, addProductToCart]);

  // const addProductGuestCart = useCallback(() => {
  //   if (cartProductId) {
  //     setProductGuestCart({ data: cartProductId, type: 'new' });
  //   }
  // }, [cartProductId, setProductGuestCart]);

  // const newChat = useCallback((userId?: string | number) => {
  //   createChatFetch({ userId });
  // }, []);

  if (loading || loadingMe || !product) return <Preloader />;
  return (
    <>
      <AlertDialog
        open={alertStockOver}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertStockOver(false);
        }}
        handleAgree={() => {
          setAlertStockOver(false);
        }}
      />

      <AlertDialog
        open={isAlertSubscribes}
        message={
          !me
            ? intl.formatMessage({ id: 'CART.PRODUCTS.SUBSCRIBES.ERROR' })
            : intl.formatMessage({ id: 'CART.PRODUCTS.SUBSCRIBES.ERROR.ME' })
        }
        okText={
          !me
            ? intl.formatMessage({ id: 'SUBMENU.AUTHORIZE' })
            : intl.formatMessage({ id: 'CART.PRODUCTS.SUBSCRIBES.ERROR.PROFILE' })
        }
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertSubscribes(false);
        }}
        handleAgree={() => {
          !me ? history.push(`/auth/main`) : history.push(`/settings/profile`);
          setAlertSubscribes(false);
        }}
      />

      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.leftCol}>
            <ImageGallery product={product} />

            {window.innerWidth > 767 ? (
              <></>
            ) : (
              <div
                style={{
                  marginLeft: 2,
                  marginTop: 10,
                }}
              >
                <p className={classes.name}>
                  <b>{product.name}</b>
                </p>
                <div>
                  <p className={classes.price}>{thousands(product.price.toString())} ₽</p>
                </div>
                <Formik
                  initialValues={{ test: 'test' }}
                  onSubmit={() => handleCartDialog(product)}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                        <Button
                          disabled={
                            addProductLoading ||
                            (isBuyer || !me
                              ? !product.stock_info?.available_quantity
                              : !product.stock_quantity)
                          }
                          onClick={() => handleCartDialog(product)}
                          color='primary'
                          variant='contained'
                          className={classes.addToCart}
                          style={{ marginTop: 10 }}
                        >
                          {(
                            isBuyer || !me
                              ? !product.stock_info?.available_quantity
                              : !product.stock_quantity
                          )
                            ? fm('PRODUCT.BUTTON.SOLD.OUT')
                            : fm('PRODUCT.BUTTON.ADD_CART')}
                        </Button>
                      )}
                      {!productCount
                        ? !isAdmin &&
                          !isVendor && (
                            <></>
                            // <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                            //   {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                            // </ButtonWithLoader>
                          )
                        : product &&
                          Boolean(productCount) && (
                            <div
                              style={{
                                marginTop: 10,
                              }}
                            >
                              <ButtonGroup
                                className='productView-btn-group'
                                color='primary'
                                // color='secondary'
                                aria-label='outlined primary button group'
                                variant='contained'
                              >
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                          type: 'dec',
                                        })
                                      : setCountGuestCart({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                          type: 'dec',
                                        })
                                  }
                                >
                                  <RemoveIcon fontSize='small' />
                                </Button>
                                <Button
                                  style={{
                                    pointerEvents: 'none',
                                  }}
                                >
                                  {productCount}
                                </Button>
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: ++productCount!,
                                          type: 'inc',
                                        })
                                      : handleCartDialog(product)
                                  }
                                >
                                  <AddIcon fontSize='small' />
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}
                      <div
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.WEIGHT')}: {product?.stock_info?.weight}{' '}
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.LENGTH')}: {product?.stock_info?.length}
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.WIDTH')}: {product?.stock_info?.width}{' '}
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.HEIGHT')}: {product?.stock_info?.height}{' '}
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.STOCK.EXPIRATION')}:{' '}
                          {product?.stock_info?.expiration_date
                            ? moment(product?.stock_info?.expiration_date).format('DD-MM-YYYY')
                            : 'Не указан'}
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            <div>
              {!!product.description && (
                <RichEditor
                  className={classes.editor}
                  disabled
                  editorState={descriptionHtml}
                  placeholder=''
                  setEditorState={() => {}}
                  style={{ padding: 0, marginLeft: 5 }}
                  productView
                />
              )}
            </div>
          </div>

          <div className={classes.rightCol}>
            {window.innerWidth < 767 ? (
              <></>
            ) : (
              <div>
                <p className={classes.name}>
                  <b>{product.name}</b>
                </p>
                <div>
                  <p className={classes.price}>{thousands(product.price.toString())} ₽</p>
                </div>
                <Formik
                  initialValues={{ test: 'test' }}
                  onSubmit={() => handleCartDialog(product)}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                        <>
                          <Button
                            disabled={
                              addProductLoading ||
                              (isBuyer || !me
                                ? !product.stock_info?.available_quantity
                                : !product.stock_quantity)
                            }
                            onClick={() => handleCartDialog(product)}
                            color='primary'
                            variant='contained'
                            className={classes.addToCart}
                          >
                            {(
                              isBuyer || !me
                                ? !product.stock_info?.available_quantity
                                : !product.stock_quantity
                            )
                              ? fm('PRODUCT.BUTTON.SOLD.OUT')
                              : intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                          </Button>
                          {!product.stock_info?.available_quantity && (
                            <Button
                              disabled={
                                addProductLoading ||
                                (isBuyer || !me
                                  ? !!product.stock_info?.available_quantity
                                  : !!product.stock_quantity)
                              }
                              onClick={() => {
                                product && product.id && handleAddSubscribes(product.id);
                              }}
                              color='primary'
                              variant='contained'
                              className={classes.addToCart}
                            >
                              {(
                                isBuyer || !me
                                  ? !product.stock_info?.available_quantity
                                  : !product.stock_quantity
                              )
                                ? fm('PRODUCT.BUTTON.TRACK')
                                : intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                            </Button>
                          )}
                          {(
                            isBuyer || !me
                              ? !product.stock_info?.available_quantity
                              : !product.stock_quantity
                          ) ? (
                            <>
                              {activeSubscribes ? (
                                <img
                                  alt=''
                                  src='/images/heartActive.svg'
                                  style={{
                                    marginLeft: 15,
                                  }}
                                />
                              ) : (
                                <img
                                  alt=''
                                  src='/images/heart.svg'
                                  style={{
                                    marginLeft: 15,
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {!productCount
                        ? !isAdmin &&
                          !isVendor && (
                            <></>
                            // <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                            //   {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                            // </ButtonWithLoader>
                          )
                        : product &&
                          Boolean(productCount) && (
                            <div
                              style={{
                                marginTop: 10,
                              }}
                            >
                              <ButtonGroup
                                className='productView-btn-group'
                                color='primary'
                                // color='secondary'
                                aria-label='outlined primary button group'
                                variant='contained'
                              >
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                          type: 'dec',
                                        })
                                      : setCountGuestCart({
                                          product_id: product.id || 0,
                                          count: --productCount!,
                                          type: 'dec',
                                        })
                                  }
                                >
                                  <RemoveIcon fontSize='small' />
                                </Button>
                                <Button
                                  style={{
                                    pointerEvents: 'none',
                                  }}
                                >
                                  {productCount}
                                </Button>
                                <Button
                                  // disabled={setProductCountLoading}
                                  onClick={() =>
                                    isAuthorized
                                      ? setProductCount({
                                          product_id: product.id || 0,
                                          count: ++productCount!,
                                          type: 'inc',
                                        })
                                      : handleCartDialog(product)
                                  }
                                >
                                  <AddIcon fontSize='small' />
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}
                      <div
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.WEIGHT')}: {product?.stock_info?.weight} гр.
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.LENGTH')}: {product?.stock_info?.length} мм
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.WIDTH')}: {product?.stock_info?.width} мм
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.HEIGHT')}: {product?.stock_info?.height} мм
                        </p>
                        <p className={classes.parameterName}>
                          {fm('PRODUCT.EDIT.STOCK.EXPIRATION')}:{' '}
                          {product?.stock_info?.expiration_date
                            ? moment(product?.stock_info?.expiration_date).format('DD-MM-YYYY')
                            : 'Не указан'}
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            <div className={classes.infoWrapper}>
              <div
                style={{
                  marginLeft: 2,
                }}
              >
                {Object.keys(characteristics).map(key => (
                  <>
                    {characteristics[key] ? (
                      <div key={key} className={classes.listItem}>
                        <p className={classes.listKey}>{key}</p>
                        <p className={classes.listValue}>{characteristics[key]}</p>
                      </div>
                    ) : null}
                  </>
                ))}
                <div className={classes.listItem}>
                  <p className={classes.listKey}>{fm('PRODUCT.VIEW.SKU')}</p>
                  <p className={classes.listValue}>{product.sku}</p>
                </div>
                {product.stock_info?.barcode && (
                  <div className={classes.listItem}>
                    <p className={classes.listKey}>{fm('PRODUCT.EDIT.STOCK.BARCODE')}</p>
                    <p className={classes.listValue}>{product.stock_info.barcode}</p>
                  </div>
                )}
              </div>

              {companyInfo && (
                <div className={classes.companyCard}>
                  {Object.keys(companyInfo).map(key => (
                    <>
                      {companyInfo[key] && (
                        <div key={key} style={{ marginBottom: 5 }}>
                          <p className={classes.listKey}>{key}</p>
                          <p
                            className={classes.listValue}
                            dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
              {isBuyer ||
                (isVendor && (
                  <>
                    <Button
                      style={{ width: '100%', marginTop: 10 }}
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        // newChat(me?.id)
                        history.push({
                          pathname: `/chats`,
                          state: {
                            createUserChat: me,
                          },
                        });
                      }}
                    >
                      {fm('Поддержка')}
                    </Button>
                  </>
                ))}
            </div>

            {/* {product.company && product.company.phone_number && ( */}
            {/*   <a */}
            {/*     rel='noreferrer' */}
            {/*     target='_blank' */}
            {/*     href={`https://wa.me/${cleanPhone(product.company.phone_number)}`} */}
            {/*     className={classes.whatsAppButton} */}
            {/*   > */}
            {/*     {intl.formatMessage({ id: 'PRODUCT.VIEW.WHATSAPP' })} */}
            {/*   </a> */}
            {/* )} */}

            {/* <div style={{ marginTop: 30 }}> */}
            {/*   <ReviewsList productId={id} meAdd={meAdd} /> */}
            {/* </div> */}

            {/* <ReviewComponent product={product} meAdd={meAdd} name={me?.fio || ''} /> */}
          </div>

          <div className={classes.reviewsContent}>
            <div className={classes.leftCol}>
              <ReviewsTextComponent
                product={product}
                meAdd={meAdd}
                name={me?.fio || ''}
                isAuthorized={isAuthorized}
              />
            </div>

            <div className={classes.rightCol}>
              <ReviewComponent product={product} meAdd={meAdd} name={me?.fio || ''} />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
    addProductError: state.cart.addProductError,
    addProductSuccess: state.cart.addProductSuccess,
    byIdError: state.products.byIdError,
    editLoadingSubscribes: state.products.editLoadingSubscribes,
    editSubscribesSuccess: state.products.editSubscribesSuccess,
    editSubscribesError: state.products.editSubscribesError,
    delLoadingSubscribes: state.products.delLoadingSubscribes,
    delSubscribesSuccess: state.products.delSubscribesSuccess,
    delSubscribesError: state.products.delSubscribesError,
    byIdSuccess: state.products.byIdSuccess,
  }),
  {
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
    clearAddProduct: cartTypesActions.clearAddProduct,
    clearById: productActions.clearById,
    addProductSubscribes: productActions.addProductSubscribes,
    delProductSubscribes: productActions.delProductSubscribes,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
