/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';
import DockIcon from '@material-ui/icons/Dock';
import { actions as authActions } from '../../../app/store/ducks/auth.duck';
import { actions as supplyActions } from '../../../app/store/ducks/supply.duck';

import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { API_DOMAIN } from '../../../app/constants';
import { UserRoles } from '../../../app/interfaces/user';
import { useDefineUserRole } from '../../../app/hooks';
import MessageIcon from '@material-ui/icons/MessageOutlined';

const useStyles = makeStyles(theme => ({
  headerBtn: {
    marginLeft: theme.spacing(3),
  },
  authText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cartText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cartBadge: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 0,
      transform: 'translateX(110%)',
    },
  },
  cartBtn: {
    marginRight: 0,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginRight: 40,
    },
  },
  profileAvatar: {
    width: 25,
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: 60,
    },
  },
  cartAvatarMobile: {
    display: 'block',
    margin: 0,
    padding: 0,
    maxWidth: 25,
    minWidth: 25,
    marginLeft: 20,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const UserProfile = ({
  me,
  loading,
  showHi,
  showAvatar,
  showBadge,
  isAuthorized,
  cartLenght,
  guestCartLenght,
  fetch,
  supplyLength,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const history = useHistory();
  const classes = useStyles();
  const userRoles = me?.roles || [];
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const { setNextUrl } = authActions;

  useEffect(() => {
    isRoleVendor && fetch();
  }, [isRoleVendor]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {userRoles?.[0] !== 'ROLE_VENDOR' && !userRoles?.includes('ROLE_ADMIN') && (
        <>
          {userRoles?.includes('ROLE_BUYER') && (
            <Link
              to={`/chats`}
              className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
              style={{ marginRight: 15 }}
            >
              <MessageIcon fontSize='large' />
              <div
                className={`kt-menu__link-text  ${classes.cartText}`}
                style={{ fontWeight: 700 }}
              >
                <FormattedMessage id='SUBMENU.CHATS' defaultMessage={'SUBMENU.CHATS'} />
              </div>
            </Link>
          )}
          <Link
            to={`/cart`}
            className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
          >
            {/* <i className={`kt-menu__link-icon flaticon-cart kt-menu-cart-icon`} style={{ fontSize: 20 }} /> */}
            <img
              alt='Select file'
              className={`kt-menu__link-icon  kt-menu-cart-icon`}
              src={toAbsoluteUrl('/images/basket-icon.svg')}
            />
            <div
              className={`kt-menu__link-text  ${classes.cartText}`}
              style={{ fontWeight: 700 ,paddingTop: '4px'}}
            >
              <FormattedMessage
                id='CART.SUBHEADER.TITLE'
                defaultMessage={'CART.SUBHEADER.TITLE'}
              />
            </div>
            <div className={classes.cartBadge}>
              {Boolean(isAuthorized) && cartLenght > 0 && (
                <span className='kt-menu__link-badge'>
                  <span
                    className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                  >
                    {cartLenght}
                  </span>
                </span>
              )}
              {!Boolean(isAuthorized) && guestCartLenght > 0 && (
                <span className='kt-menu__link-badge'>
                  <span
                    className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                  >
                    {guestCartLenght}
                  </span>
                </span>
              )}
            </div>
          </Link>
        </>
      )}

      {/* TODO */}
      {!me?.roles.includes(UserRoles.ROLE_BUYER) &&
        !userRoles?.includes('ROLE_ADMIN') &&
        isAuthorized &&
        me && (
          <Link
            to={`/supply`}
            className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
          >
            <DockIcon fontSize='large' color='primary' />
            <div
              className={`kt-menu__link-text  ${classes.cartText}`}
              style={{ fontWeight: 700 }}
            >
              <FormattedMessage
                id='SUPPLY.SUBHEADER.TITLE'
                defaultMessage={'SUPPLY.SUBHEADER.TITLE'}
              />
            </div>
            <div className={classes.cartBadge}>
              {Boolean(isAuthorized) && (
                <span className='kt-menu__link-badge'>
                  <span
                    className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                  >
                    {supplyLength}
                  </span>
                </span>
              )}
            </div>
          </Link>
        )}

      {isAuthorized ? (
        <Dropdown
          className='kt-header__topbar-item kt-header__topbar-item--user'
          drop='down'
          alignRight
        >
          <Dropdown.Toggle as={HeaderDropdownToggle} id='dropdown-toggle-user-profile'>
            <div className='kt-header__topbar-user kt-menu__link'>
              {/* {showHi && (
                <span className='kt-header__topbar-username kt-hidden-mobile '>
                  {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' })},
                </span>
              )} */}

              {/* {showHi && ( */}
              {/*   <span className='kt-header__topbar-username kt-hidden-mobile'>{me.fio}</span> */}
              {/* )} */}

              {showAvatar && (
                <div className={`kt-user-card__avatar ${classes.profileAvatar}`}>
                  {me?.avatar ? (
                    <img
                      sizes={'cover'}
                      style={{
                        objectFit: 'cover',
                        width: 25,
                        height: 25,
                        borderRadius: '50%',
                        borderColor: '#005a5a',
                        borderWidth: 2,
                        borderStyle: 'solid',
                      }}
                      className={`kt-menu__link-icon  kt-menu-cart-icon`}
                      src={`${API_DOMAIN}/${me?.avatar}`}
                      alt=''
                    />
                  ) : (
                    <img
                      alt='Select file'
                      // style={{maxHeight: 25}}
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '100%',
                        maxHeight: 25,
                        cursor: 'pointer',
                      }}
                      className={`kt-menu__link-icon  kt-menu-cart-icon ${classes.avatar}`}
                      src={toAbsoluteUrl('/images/profile-circle.svg')}
                    />
                    // <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    // </span>
                  )}
                  <div
                    className={`kt-menu__link-text  ${classes.cartText}`}
                    style={{ fontWeight: 700,paddingTop: '4px' ,textAlign: 'center'}}
                  >
                    <FormattedMessage
                      id='SETTING.PROFILE.EDIT'
                      defaultMessage={'SETTING.PROFILE.EDIT'}
                    />
                  </div>
                </div>
              )}

              {/* {showBadge && ( */}
              {/*   <span className='kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold'> */}
              {/*     /!* TODO: Should get from currentUser *!/ */}
              {/*     {me.fio} */}
              {/*   </span> */}
              {/* )} */}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div
              className='kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x'
              style={{
                background: 'rgb(0, 90, 90)',
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/menu-pic.png')})`,

                // backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div
                className='kt-user-card__avatar'
                style={{ overflow: 'hidden', width: 60, height: 60, borderRadius: 4 }}
              >
                {me?.avatar ? (
                  <img
                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    src={`${API_DOMAIN}/${me?.avatar}`}
                    alt=''
                  />
                ) : (
                  <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    <b>{(me?.fio ? me?.fio[0] : '').toUpperCase()}</b>
                  </span>
                )}
              </div>
              <div className='kt-user-card__name' style={{ fontWeight: '700' }}>
                {me?.fio}
              </div>
            </div>
            <div className='kt-notification'>
              <Link to='/settings/profile' className='kt-notification__item'>
                <div className='kt-notification__item-icon'>
                  <i className='flaticon2-calendar-3 kt-font-success  icon-profile' />
                </div>
                <div className='kt-notification__item-details'>
                  <div className='kt-notification__item-title' style={{ fontWeight: '700' }}>
                    {intl.formatMessage({ id: 'SUBMENU.PROFILE' })}
                  </div>
                  <div
                    className='kt-notification__item-time color09090'
                    style={{ fontWeight: '600' }}
                  >
                    {intl.formatMessage({ id: 'SUBMENU.ACCOUNT_SETTINGS' })}
                  </div>
                </div>
              </Link>
              {me?.roles.includes(UserRoles.ROLE_BUYER) && (
                <>
                  <Link to='/orders/list/full' className='kt-notification__item'>
                    <div className='kt-notification__item-icon'>
                      <i className='flaticon-bag kt-font-success  icon-profile' />
                    </div>
                    <div className='kt-notification__item-details'>
                      <div
                        className='kt-notification__item-title'
                        style={{ fontWeight: '700' }}
                      >
                        {intl.formatMessage({ id: 'MENU.ORDERS' })}
                      </div>
                    </div>
                  </Link>
                  <Link to='/orders/list/history' className='kt-notification__item'>
                    <div className='kt-notification__item-icon'>
                      <i className='flaticon-clock-2 kt-font-success  icon-profile' />
                    </div>
                    <div className='kt-notification__item-details'>
                      <div
                        className='kt-notification__item-title'
                        style={{ fontWeight: '700' }}
                      >
                        {intl.formatMessage({ id: 'SUBMENU.HISTORY' })}
                      </div>
                    </div>
                  </Link>
                </>
              )}

              {/* {me?.roles.includes(UserRoles.ROLE_VENDOR) && (
                <>
                  <Link to='/delivery/list/full' className='kt-notification__item'>
                    <div className='kt-notification__item-icon'>
                      <i className='flaticon-bag kt-font-success  icon-profile' />
                    </div>
                    <div className='kt-notification__item-details'>
                      <div
                        className='kt-notification__item-title'
                        style={{ fontWeight: '700' }}
                      >
                        {intl.formatMessage({ id: 'MENU.DELIVERY.DOCUMENTS' })}
                      </div>
                    </div>
                  </Link>
                  <Link to='/delivery/list/history' className='kt-notification__item'>
                    <div className='kt-notification__item-icon'>
                      <i className='flaticon-clock-2 kt-font-success  icon-profile' />
                    </div>
                    <div className='kt-notification__item-details'>
                      <div
                        className='kt-notification__item-title'
                        style={{ fontWeight: '700' }}
                      >
                        {intl.formatMessage({ id: 'SUBMENU.DELIVERY.HISTORY' })}
                      </div>
                    </div>
                  </Link>
                </>
              )} */}
              <div className='kt-notification__custom'>
                <Link
                  to='/logout'
                  className='btn btn-label-brand btn-sm'
                  style={{ fontWeight: '700' }}
                >
                  {intl.formatMessage({ id: 'SUBMENU.EXIT' })}
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className='kt-login__signup' style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              dispatch(setNextUrl(history.location.pathname));
              history.push('/auth');
            }}
            className={`${classes.headerBtn} ${classes.cartText}`}
            variant='contained'
            color='secondary'
          >
            {intl.formatMessage({ id: 'SUBMENU.AUTHORIZE' })}
          </Button>
          <Button
            onClick={() => {
              dispatch(setNextUrl(history.location.pathname));
              history.push('/auth');
            }}
            className={classes.cartAvatarMobile}
          >
            <img
              alt='Select file'
              // style={{maxHeight: 25}}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxHeight: 25,
              }}
              className={`kt-menu__link-icon  kt-menu-cart-icon`}
              src={toAbsoluteUrl('/images/profile-circle.svg')}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  me: store.profile.me,
  loading: store.profile.loading,
  cartLenght: store.cart.cart?.goods_num,
  guestCartLenght: store.cart.guestCart?.goods_num,
  isAuthorized: store.auth.user != null,
  supplyLength: store.supply.accord?.document_products
    ? store.supply.accord.document_products.length
    : 0,
});
export default connect(mapStateToProps, {
  fetch: supplyActions.fetchAccord,
})(UserProfile);
