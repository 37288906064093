import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { editPostsStatus } from '../../../../crud/blog.crud';

export const useChangePostsStatus = (onSuccess?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [statusLoading, setStatusLoading] = useState(false);
  const [err, setErr] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const changePostStatus = useCallback(
    async (id: number, status: string) => {
      setStatusLoading(true);
      editPostsStatus(id, status)
        .then(res => {
          setSuccess(true);
          onSuccess?.()
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setStatusLoading(false);
          setErr(null);
          setSuccess(false);
        });
    },
    []
  );

  return [changePostStatus, statusLoading, success, err];
};




