import { makeStyles, createStyles } from '@material-ui/core';

export const useStylesReviewsList = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 10,
  },

  textTotalRating: {
    fontStyle: 'bold',
    fontWeight: 600,
    color: '#000',
    marginBottom: 30,
    textAlign: 'center',
  },

  wrapperBlock: {
    marginBottom: '2.25rem',
  },

  wrapperUserInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    flexWrap: 'wrap',
  },

  wrapperUserAvatar: {
    display: 'inline-block',
    flexShrink: 0,
    position: 'relative',
    borderRadius: '.475rem',
    marginRight: '1.25rem',
  },

  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: '0.475rem',
  },

  userFio: {
    fontWeight: 600,
    fontSize: '1.15rem',
    wordWrap: 'break-word',
    color: '#3f4254',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  reviewText: {
    margin: 0,
    fontWeight: 400,
    fontSize: '1.15rem',
    color: '#5e6278',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },

  wrapperPaginator: {
    display: 'flex',
    justifyContent: 'center',
  },

  titleContent: {
    display: 'flex',
    width:'100%',
    flexDirection:'column'
  },

  ratingContent: {
    display:'flex',
    justifyContent:'flex-start',
    marginTop: '20px'
  },

  reviewContent: {
    display: 'flex',
    flexDirection: 'column', 
    width: '400px'
  }
}));

export const useStylesProductCatalog = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      padding: '0',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    wrapContent: {
      width: 'calc(100% - 326px - 30px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    input: {
      width: '100%',
      padding: '14.5px 14px',
      borderWidth: 0,
      borderRadius: 4,
      outline: 'none',
      boxSizing: 'border-box',
    },
    inputBuyer: {
      width: '100%',
      padding: '14.5px 14px',
      borderWidth: 0,
      borderRadius: 4,
      outline: 'none',
      boxSizing: 'border-box',
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
      color: 'rgb(0, 90, 90)',
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    cardCategory: {
      marginBottom: theme.spacing(3),
    },
    cardButtons: {
      justifyContent: 'flex-end',
      padding: 16,
    },
    count: {
      fontStyle: 'bold',
      fontWeight: 600,
      color: '#000',
      marginRight: 15,
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        width: 326,
      },
    },
    productsCol: {
      // [theme.breakpoints.up('sm')]: {
      //   width: 'calc(100% - 30px)',
      // },
    },
    productColHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    productColHeaderItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    searchField: {
      width: 300,
      margin: 0,
      marginLeft: 30,
      '& .MuiOutlinedInput-root': {
        height: 48,
      },
    },
    searchBtn: {
      marginLeft: 30,
    },
    categoriesList: {
      marginRight: 30,
      width: 326,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      // [theme.breakpoints.down('md')]: {
      //   display: 'none',
      // },
    },
    labelText: {
      fontWeight: 500,
      flexGrow: 1,
      fontSize: 14,
    },
    revers: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    filtersDisplay: {
      display: 'none',

      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
  })
);
