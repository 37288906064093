import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { getResponseMessage } from '../../utils/utils';
import { getOrderStatistics, getProductStatistics } from '../../crud/statistics.crud';
import { IStatProductItem, IStatistic } from '../../interfaces/statistic';

const FETCH_ORDERS_REQUEST = 'stat/FETCH_ORDERS_REQUEST';
const FETCH_ORDERS_SUCCESS = 'stat/FETCH_ORDERS_SUCCESS';
const FETCH_ORDERS_FAIL = 'stat/FETCH_ORDERS_FAIL';

const FETCH_PRODUCTS_REQUEST = 'stat/FETCH_PRODUCTS_REQUEST';
const FETCH_PRODUCTS_SUCCESS = 'stat/FETCH_PRODUCTS_SUCCESS';
const FETCH_PRODUCTS_FAIL = 'stat/FETCH_PRODUCTS_FAIL';

export interface IInitialState {
  statisticOrders: IStatistic | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  statisticProduct: IStatProductItem[] | undefined;
  loadingProducts: boolean;
  successProducts: boolean;
  errorProducts: string | null;
}

const initialState: IInitialState = {
  statisticOrders: undefined,
  loading: false,
  success: false,
  error: null,

  statisticProduct: undefined,
  loadingProducts: false,
  successProducts: false,
  errorProducts: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'statistics', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ORDERS_REQUEST: {
        return {
          ...state,
          statisticOrders: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_ORDERS_SUCCESS: {
        return {
          ...state,
          statisticOrders: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_ORDERS_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_PRODUCTS_REQUEST: {
        return {
          ...state,
          statisticProduct: undefined,
          loadingProducts: true,
          successProducts: false,
          errorProducts: null,
        };
      }

      case FETCH_PRODUCTS_SUCCESS: {
        return {
          ...state,
          statisticProduct: action.payload.data,
          loadingProducts: false,
          successProducts: true,
        };
      }

      case FETCH_PRODUCTS_FAIL: {
        return { ...state, loadingProducts: false, errorProducts: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchOrdersRequest: (payload: {
    date_from?: string;
    date_to?: string;
    company_id?: number | string;
    accord_status?: string;
  }) => createAction(FETCH_ORDERS_REQUEST, payload),
  fetchOrdersSuccess: (payload: IServerResponse<IStatistic>) =>
    createAction(FETCH_ORDERS_SUCCESS, payload),
  fetchOrdersFail: (payload: string) => createAction(FETCH_ORDERS_FAIL, payload),

  fetchProductsRequest: (payload: {
    date_from?: string;
    date_to?: string;
    company_id?: number;
    sort_for?: string;
    sort_type?: string;
  }) => createAction(FETCH_PRODUCTS_REQUEST, payload),
  fetchProductsSuccess: (payload: IServerResponse<IStatProductItem[]>) =>
    createAction(FETCH_PRODUCTS_SUCCESS, payload),
  fetchProductsFail: (payload: string) => createAction(FETCH_PRODUCTS_FAIL, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchOrdersSaga({
  payload,
}: {
  payload: {
    date_from?: string;
    date_to?: string;
    company_id?: number | string;
    accord_status?: string;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IStatistic> } = yield call(() =>
      getOrderStatistics(
        payload.date_from,
        payload.date_to,
        payload.company_id,
        payload.accord_status
      )
    );
    yield put(actions.fetchOrdersSuccess(data));
  } catch (e) {
    yield put(actions.fetchOrdersFail(getResponseMessage(e)));
  }
}

function* fetchProductsSaga({
  payload,
}: {
  payload: {
    date_from?: string;
    date_to?: string;
    company_id?: number;
    sort_for?: string;
    sort_type?: string;
  };
}) {
  try {
    const { data }: { data: IServerResponse<IStatProductItem[]> } = yield call(() =>
      getProductStatistics(
        payload.date_from,
        payload.date_to,
        payload.company_id,
        payload.sort_for,
        payload.sort_type
      )
    );
    yield put(actions.fetchProductsSuccess(data));
  } catch (e) {
    yield put(actions.fetchProductsFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchOrdersRequest>>(
    FETCH_ORDERS_REQUEST,
    fetchOrdersSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchProductsRequest>>(
    FETCH_PRODUCTS_REQUEST,
    fetchProductsSaga
  );
}
