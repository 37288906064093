import axios from 'axios';
import { Cookies } from 'react-cookie';
import { ROISTAT_DOMAIN } from '../constants';

const cookies = new Cookies();

export const createEvent = (display_name: 'cart' | 'paid_order' | 'registration') => {
  const options = {
    params: { project: '243753' },
    headers: { 'Content-Type': 'application/json' },
    baseURL: ROISTAT_DOMAIN,
  };
  return axios.post(
    '/project/events/add',
    [{ display_name, type: 'js', parameter: display_name }],
    options
  );
};

export const getLogsEvents = () => {
  const options = {
    params: { project: '243753' },
    headers: { 'Content-Type': 'application/json' },
    baseURL: ROISTAT_DOMAIN,
  };
  return axios.get('/project/events/log', options);
};

export const sendEventReg = async (data: any) => {
  const visit = cookies.get('roistat_visit');
  const options = {
    params: { project: '243753' },
    headers: { 'Content-Type': 'application/json' },
    baseURL: ROISTAT_DOMAIN,
  };
  // await createEvent('registration');
  return axios.post(
    '/project/events/send',
    { name: 'registration', data, visit: visit.toString() },
    options
  );
};

export const sendEventAddProductCart = async (data: any) => {
  const visit = cookies.get('roistat_visit');
  const options = {
    params: { project: '243753' },
    headers: { 'Content-Type': 'application/json' },
    baseURL: ROISTAT_DOMAIN,
  };
  // await createEvent('cart');
  return axios.post(
    '/project/events/send',
    { name: 'cart', data, visit: visit.toString() },
    options
  );
};

export const sendEventPaidOrder = async (data: any) => {
  const visit = cookies.get('roistat_visit');
  const options = {
    params: { project: '243753' },
    headers: { 'Content-Type': 'application/json' },
    baseURL: ROISTAT_DOMAIN,
  };
  // await createEvent('paid_order');
  return axios.post(
    '/project/events/send',
    { name: 'paid_order', data, visit: visit.toString() },
    options
  );
};
