import React from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import { Link } from 'react-router-dom';


import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import Preloader from '../../other/Preloader/Preloader';
import { API_DOMAIN } from '../../../constants';
import { toAbsoluteUrl } from '../../../../_metronic';
import { IProduct } from '../../../interfaces/product';
import { IPaginationData } from '../../../interfaces/server';
import { IEditPublication } from '../../../pages/home/blog/interfaces';

interface IProps {
  products: IProduct[]
  loading?: boolean;
  onEdit?: (id: number) => any;
  onConnectOff?: (id: number) => any;
  onConnect?: (product: IProduct) => any;
  fetch?: (data: { page: number; perPage: number }) => void;
  productId?: string;
  values: IEditPublication
  page: number
  perPage: number
  total: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,

      // [theme.breakpoints.up('xl')]: {
      //   minWidth: 500,
      // },
      // // @ts-ignore
      // [theme.breakpoints.up('xxl')]: {
      //   width: 500,
      // },
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    img: {
      height: 55,
      width: 70,
      objectFit: 'cover',
    },
  })
);

const PublicationTable: React.FC<IProps> = ({
  products,
//   loading,
  onConnectOff,
  onConnect,
  onEdit,
  fetch,
  values,
  page,
  perPage,
  total
}) => {
  const intl = useIntl();
  const classes = useStyles();


  return (
    <Table aria-label='stores' className={classes.table}>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell>Изображение</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}</SHeadTableCell>
          <SHeadTableCell>Цена</SHeadTableCell>
          <SHeadTableCell>Кампания</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'COMPANY.TABLE.ACTIONS' })}</SHeadTableCell>
        </TableRow>
      </TableHead>

      <TableBody>
         {products.map(item => (
          <TableRow key={item.id}>
            <TableCell component='th' scope='row'>
              {item.id}
            </TableCell>
            <TableCell>
              <img
                src={
                  // @ts-ignore
                  item.photos[0]?.small
                  // @ts-ignore
                    ? `${API_DOMAIN}/${item.photos[0].small}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                className={classes.img}
                alt={item.name}
              />
          </TableCell>
            <TableCell>
              <Link to={`/viewproduct/${item.id}`}>
                {item?.name}
              </Link>
              </TableCell>
            <TableCell style={{width: 200}}>{item?.price} ₽</TableCell>
            <TableCell>{item?.company?.name || '-'}</TableCell>
            <TableCell>
              {/* <IconButton
                color='primary'
                aria-label='previous'
                // onClick={() => onEdit(item?.id)}
              >
                <EditIcon />
              </IconButton> */}

              {/* {item.favorite && (
                <IconButton 
                onClick={() => onConnectOff(item?.id)}
                >
                  <LinkOffIcon color='secondary' />
                </IconButton>
              )}  */}

              {/* {(!item.favorite) && ( */}
                <IconButton
                  onClick={
                    //  item.favorite
                    //   ? () => onConnectOff(item.id)
                    // @ts-ignore
                     () => onConnect(item)
                  }
                >
                  {values.product_id === item.id ? (
                     <LinkOffIcon color='secondary' />
                  ) : (
                    <LinkIcon color='primary' />
                  )}
                </IconButton>
              {/* )} */}
            </TableCell>
          </TableRow>
        ))} 
      </TableBody>
      
        <TableFooter>
          <TableRow>
            <TablePaginator
              page={page}
              perPage={perPage}
              total={total}
              realPerPage={products.length}
              fetchRows={fetch}
              label='Товары на странице'
            />
          </TableRow>
        </TableFooter>
    </Table>
  );
};

export default PublicationTable;
