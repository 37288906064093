import * as React from 'react';
import { ProductCatalogList } from '../components/Menu';

export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
    activePages: ['settings/profile'],
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    // icon: 'flaticon-logout',
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'categories', 'viewproduct'],
    inActivePages: ['products/types'],
    submenu: [
      {
        title: 'PRODUCT.ALL.PRODUCTS',
        translate: 'PRODUCT.ALL.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      {
        title: 'PRODUCT.ALL.NEW_PRODUCTS',
        page: 'products/new',
        translate: 'PRODUCT.ALL.NEW_PRODUCTS',
      },
      {
        title: 'PRODUCT.ALL.FAVORITES_PRODUCTS',
        page: 'products/favorites',
        translate: 'PRODUCT.ALL.FAVORITES_PRODUCTS',
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },

  vendorProducts: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    activePages: ['products', 'categories', 'viewproduct'],
    submenu: [
      {
        title: 'MENU.CATALOG.PRODUCTS',
        translate: 'MENU.CATALOG.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      {
        title: 'MENU.COMPANY.PRODUCTS',
        page: 'products/list',
        translate: 'MENU.COMPANY.PRODUCTS',
        activePages: ['products/edit', 'products/create'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },

  vendorCompany: {
    root: true,
    title: 'MENU.VENDOR.COMPANY',
    translate: 'MENU.VENDOR.COMPANY',
    bullet: 'dot',
    activePages: ['companies', 'stores'],
    submenu: [
      {
        translate: 'SUBMENU.COMPANY_SETTINGS',
        title: 'SUBMENU.COMPANY_SETTINGS',
        page: 'companies/list',
        activePages: ['companies/edit', 'companies/new'],
      },
    ],
  },

  orders: {
    root: true,
    title: 'MENU.ORDERS',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    page: 'orders/list/full',
    activePages: ['orders/'],
    submenu: [
      {
        translate: 'SUBMENU.ALLORDERS',
        title: 'SUBMENU.ALLORDERS',
        page: 'orders/list/full',
      },
      {
        title: 'MENU.DELIVERY.DOCUMENTS',
        translate: 'MENU.DELIVERY.DOCUMENTS',
        page: 'delivery/list/full',
      },
      {
        translate: 'SUBMENU.ABANDONEDCHECKOUTS',
        title: 'SUBMENU.ABANDONEDCHECKOUTS',
        page: 'orders/list/abandoned',
      },
    ],
  },

  chats: {
    root: true,
    title: 'SUBMENU.CHATS',
    translate: 'SUBMENU.CHATS',
    bullet: 'dot',
    page: 'chats',
    activePages: ['chats'],
  },

  buyersOrders: {
    root: true,
    title: 'MENU.ORDERS',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    page: 'orders/list/full',
  },

  buyersOrdersHistory: {
    root: true,
    title: 'MENU.ORDERS.HISTORY',
    translate: 'MENU.ORDERS.HISTORY',
    bullet: 'dot',
    page: 'orders/list/history',
  },

  buyersDocuments: {
    root: true,
    title: 'MENU.DELIVERY.DOCUMENTS',
    translate: 'MENU.DELIVERY.DOCUMENTS',
    bullet: 'dot',
    page: 'delivery/list/full',
  },

  buyersDocumentsHistory: {
    root: true,
    title: 'MENU.DELIVERY.DOCUMENTS.HISTORY',
    translate: 'MENU.DELIVERY.DOCUMENTS.HISTORY',
    bullet: 'dot',
    page: 'delivery/list/history',
  },

  // companies: {
  //   root: true,
  //   title: 'MENU.COMPANIES',
  //   translate: 'MENU.COMPANIES',
  //   bullet: 'dot',
  //   activePages: ['companies', 'stores', 'viewcompany'],
  //   submenu: [
  //     {
  //       translate: 'SUBMENU.COMPANIES',
  //       title: 'SUBMENU.COMPANIES',
  //       page: 'companies/list',
  //     },
  //     // {
  //     //   translate: 'MENU.CATALOG.STORES',
  //     //   title: 'MENU.CATALOG.STORES',
  //     //   page: 'stores/list',
  //     // },
  //   ],
  // },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users', 'companies', 'stores', 'viewcompany'],
    submenu: [
      {
        translate: 'MENU.COMPANIES',
        title: 'MENU.COMPANIES',
        page: 'companies/list',
      },
      {
        translate: 'SUBMENU.VENDORS',
        title: 'SUBMENU.VENDORS',
        page: 'users/vendors',
      },
      {
        translate: 'SUBMENU.BUYERS',
        title: 'SUBMENU.BUYERS',
        page: 'users/buyers',
      },
    ],
  },

  vendorUsers: {
    root: true,
    title: 'MENU.TEAM',
    translate: 'MENU.TEAM',
    bullet: 'dot',
    page: 'users/managers',
    activePages: ['users'],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    activePages: ['products/types'],
    submenu: [
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/types/list',
        activePages: ['products/types/create'],
      },
      {
        title: 'MENU.PRODUCTS.BANNERS',
        translate: 'MENU.PRODUCTS.BANNERS',
        page: 'banners/list',
        activePages: ['banners', 'banners/create', 'banners/edit'],
      },
      {
        title: 'MENU.PRODUCTS.PROMOCODES',
        translate: 'MENU.PRODUCTS.PROMOCODES',
        page: 'promocodes/list',
        activePages: ['promocodes', 'promocodes/edit', 'promocodes/new'],
      },
      {
        title: 'MENU.PRODUCTS.REFFERALS',
        translate: 'MENU.PRODUCTS.REFFERALS',
        page: 'refferals/list',
        activePages: ['refferals', 'refferals/edit', 'refferals/new'],
      },
      {
        title: 'MENU.PRODUCTS.TRANSACTIONS',
        translate: 'MENU.PRODUCTS.TRANSACTIONS',
        page: 'transactions/list',
        activePages: ['transactions'],
      },
    ],
  },

  settingsManager: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    activePages: ['transactions'],
    submenu: [
      {
        title: 'MENU.PRODUCTS.TRANSACTIONS',
        translate: 'MENU.PRODUCTS.TRANSACTIONS',
        page: 'transactions/list',
        activePages: ['transactions'],
      },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    activePages: ['documents'],
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.COOKIE',
        translate: 'SUBMENU.COOKIE',
        page: 'documents/cookie',
      },
    ],
  },

  catalog: {
    root: true,
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    // icon: 'flaticon-book',
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'viewproduct'],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    // icon: 'flaticon-login',
    page: 'auth',
  },

  blog: {
    root: true,
    title: 'SUBMENU.BLOG',
    translate: 'SUBMENU.BLOG',
    bullet: 'dot',
    activePages: ['blog'],
    page: 'blog/my',
    submenu: [
      {
        title: 'SUBMENU.BLOG.ALL',
        page: 'blog/all',
        translate: 'SUBMENU.BLOG.ALL',
        activePages: ['blog/edit', 'blog/create', 'blog/view'],
      },
      {
        title: 'SUBMENU.BLOG.MY',
        page: 'blog/my',
        translate: 'SUBMENU.BLOG.MY',
      },
    ],
  },

  guestBlog: {
    root: true,
    title: 'SUBMENU.BLOG.ALL',
    translate: 'SUBMENU.BLOG.ALL',
    bullet: 'dot',
    page: 'blog/all',
    activePages: ['blog'],
  },

  statistic: {
    root: true,
    title: 'SUBMENU.STATISTIC',
    translate: 'SUBMENU.STATISTIC',
    bullet: 'dot',
    activePages: ['statistics'],
    submenu: [
      {
        title: 'SUBMENU.STATISTIC.ORDERS',
        page: 'statistics/list/orders',
        translate: 'SUBMENU.STATISTIC.ORDERS',
      },
      {
        title: 'SUBMENU.STATISTIC.PRODUCTS',
        page: 'statistics/list/products',
        translate: 'SUBMENU.STATISTIC.PRODUCTS',
      },
      {
        title: 'SUBMENU.DETAIL.STATISTIC.ORDERS',
        page: 'statistics/list/detailed/orders',
        translate: 'SUBMENU.DETAIL.STATISTIC.ORDERS',
      },
    ],
  },
};
