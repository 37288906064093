import axios from 'axios';
import { getDeliveriesListUrl, getDeliveryDocUrl } from '../constants';

export const getDeliveries = (page: number = 1, per_page: number = 20) => {
  return axios.get(getDeliveriesListUrl(), {
    params: {
      page,
      per_page,
    },
  });
};

export const getDeliveryById = (id: number) => {
  return axios.get(getDeliveryDocUrl(id));
};
