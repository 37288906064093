import axios from 'axios';
import { TUserFilter } from '../components/ui/UsersFilter/types';
import { CREATE_USER_URL, getUserUrl, delUserUrl, uploadUserAvatarUrl, getUsersUrl } from '../constants';
import { IUserAddProps } from '../interfaces/user';
import { appendQuerys } from '../utils/utils';

export function getUsersByRole(
  page: number,
  perPage: number,
  roles: string,
  filter: TUserFilter
) {
  let url = `/api/users/search?page=${page}&per_page=${perPage}&roles=${roles}`;

  if (filter.countries.length > 0) {
    url += `&country=${filter.countries.join(',')}`;
  }

  if (filter.languages.length > 0) {
    url += `&languages=${filter.languages.join(',')}`;
  }

  return axios.get(url);
}

export function getUsers(page: number, perPage: number) {
  return axios.get(`${getUsersUrl()}?page=${page}&per_page=${perPage}`);
}
export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data);
}

export function editUser(id: number, data: any) {
  let url = `/api/user/${id}/edit`;
  const querys = [];
  if (data.languages && data.languages.length > 0) {
    querys.push(`languages=${data.languages.join(',')}`);
  } else {
    querys.push(`languages=%20`);
  }
  if (data.country) {
    querys.push(`country=${data.country}`);
  } else {
    querys.push(`country=%20`);
  }
  if (data.password) {
    querys.push(`password=${data.password}`);
  } else {
    querys.push(`password=%20`);
  }
  url = appendQuerys(url, querys);
  return axios.put(url, data);
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function uploadUserAvatar({ photo, id }: { photo: FormData; id: string }) {
  return axios.post(uploadUserAvatarUrl(id), photo);
}
