import React from 'react';

interface IProps {
  size?: string;
  color?: string
}

const CheckIcon: React.FC<IProps> = ({ size='24',color='#78dc78' }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
        <polyline points="20 6 9 17 4 12"/>
      </svg>
    </>
  );
};

export default CheckIcon;
