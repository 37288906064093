import axios from 'axios';
import {
  GET_COMPANIES_URL,
  CREATE_COMPANY_URL,
  SEARCH_COMPANY_URL,
  editCompanyUrl,
  getCompanyUrl,
  delCompanyUrl,
  getCompanyDetailUrl,
} from '../constants';

import { ICompanyEditProps, ICompanyAddProps } from '../interfaces/companies';

export function getCompanies(page: number, perPage: number) {
  return axios.get(`${GET_COMPANIES_URL}?page=${page}&per_page=${perPage}`);
}

export function getCompany(id: number) {
  return axios.get(getCompanyUrl(id));
}

export function createCompany(data: ICompanyAddProps) {
  return axios.post(CREATE_COMPANY_URL, data);
}

export const searchCompanies = (inn: number) => axios.get(`${SEARCH_COMPANY_URL}?inn=${inn}`);

export function editCompany(id: number, data: ICompanyEditProps, favorite: boolean = false,) {
  return axios.put(editCompanyUrl(id, favorite), data);
}

export function delCompany(id: number) {
  return axios.delete(delCompanyUrl(id));
}

export function getCompanyDetail(id: number) {
  return axios.get(getCompanyDetailUrl(id));
}
