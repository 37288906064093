import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import parse from 'html-react-parser';
import { Button, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps, shallowEqual, useSelector } from 'react-redux';
import { injectIntl, WrappedComponentProps, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../../_metronic';
import EditeIcon from '../../../pages/home/products/components/EditIcon';
import { IPublication } from '../../../pages/home/blog/interfaces';
import { API_DOMAIN } from '../../../constants';
import { useFormatMessage, useDefineUserRole } from '../../../hooks';
import { formatAsThousands } from '../../../utils/utils';
import { IAppState } from '../../../store/rootDuck';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { actions as productActions } from '../../../store/ducks/product.duck';
import { actions as productTypeActions } from '../../../store/ducks/productType.duck';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as productForDaysActions } from '../../../store/ducks/product-for-days.duck';
import { IProduct } from '../../../interfaces/product';
import CheckIcon from '../../../pages/home/products/components/CheckIcon';
import { ColorButton } from '../../other/Buttons';
import { useChangePostsStatus } from './hooks/useChangeStatusPosts';
import { actions as blogActions } from '../../../store/ducks/blog.duck';


import 'moment/locale/ru';
import ButtonWithLoader from '../../other/Buttons/ButtonWithLoader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      transition: '.2s ease-out',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    content: {
      flexWrap: 'nowrap',
      gap: 30,
      [theme.breakpoints.down(1025)]: {
        flexWrap: 'wrap',
      },
    },
    rootView: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    media: {
      height: 220,
      paddingTop: '52.25%', // 16:9
      marginBottom: 10,
    },
    buttonViewContain: {
      paddingBottom: '15px',
    },
    buttonContent: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      // justifyContent: 'space-between',
      // width: '100%',
      marginTop: 25,
    },
    // btnAddProduct: {
    //   width: '100%',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: 45,
    //   [theme.breakpoints.down('md')]: {
    //     // marginBottom: 10,
    //   },
    //   padding: '0 5px',
    //   marginBottom: 5,
    // },
//     productCard: {
//       display: 'flex',
//       flexDirection: 'column',
// // width: 300,
//       // cursor: 'pointer',
//       // transition: '.3s ease-out',
//       // boxShadow: 'none !important',
//       height: 477,
//       justifyContent: 'space-between',

//       // '&:hover': {
//       //   transform: 'scale(1.03)',
//       // },

//       // [theme.breakpoints.up('sm')]: {
//       //   width: 285,
//       // },
//       // minWidth: '24.7%',
//       // marginLeft: 15,
//       cursor: 'pointer',
//       transition: '.3s ease-out',
//       boxShadow: 'none !important',

//       '&:hover': {
//         transform: 'scale(1.03)',
//       },

//       [theme.breakpoints.up('sm')]: {
//         width: 280,
//       },
//     },
    title: {
      fontWeight: 400,
      color: '#212328',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',

      [theme.breakpoints.down('sm')]: {
        width: 280,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('sm')]: {
        width: 200,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        width: 240,
        height: 42,
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        width: 250,
        height: 45,
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        width: 300,
        height: 50,
        fontSize: 18,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        minWidth: 360,
        height: 60,
        fontSize: 20,
      },
    },
    textContent: {
      color: '#000',
      wordWrap: 'break-word',
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
    },
    titleIsAdmin: {
      fontWeight: 400,
      color: '#212328',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',

      [theme.breakpoints.down('sm')]: {
        width: 270,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('sm')]: {
        width: 180,
        height: 42,
        fontSize: 14,
      },
      [theme.breakpoints.up('md')]: {
        width: 200,
        height: 42,
        fontSize: 16,
      },
      [theme.breakpoints.up('lg')]: {
        width: 210,
        height: 45,
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        width: 230,
        height: 50,
        fontSize: 18,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        minWidth: 330,
        height: 60,
        fontSize: 20,
      },
    },


    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      minWidth: '100%',
      marginBottom: 25,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',

      '&:hover': {
        transform: 'scale(1.03)',
      },

      [theme.breakpoints.up('md')]: {
        width: 220,
      },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    btnSupport: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
    },
    btnAddProduct: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        // marginBottom: 10,
      },
    },
    cardContent: {
      padding: '15px 15px 0 15px',
      height: 120,
      overflow: 'hidden',

      [theme.breakpoints.up('xl')]: {
        height: 140,
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        height: 160,
      },
    },
    columnContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    card_content: {
      paddingRight: 0,
      paddingTop: 120,
      width: '50%',

      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '50px 0 0 0'
      },
      [theme.breakpoints.up('xl')]: {
        width: '26%',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        width: '22%',
      },
    },
    name: {
      padding: 0,
      margin: 0,
      fontWeight: 'normal',
      fontFamily: "AtypTextRegular",
      fontSize: '18px',
      lineHeight: '16.9px',
    },
    price: {
      fontSize: '21px',
      fontWeight: 'bold',
      padding: 0,
      margin: 0,
    }
      })
);
interface IProps {
  publication: IPublication;
  deletePost: (id: number) => void;
  isAdmin: boolean;
  isView: boolean;
  admin?: boolean
  meId?: number
  btnPub?: boolean
  companyId?: number
  showEdit?: boolean
}

const PublicationPost: React.FC<TPropsFromRedux & IProps> = ({
  publication,
  deletePost,
  isAdmin,
  isView,
  admin,
  isAuthorized,
  addProductToCart,
  me,
  setProductGuestCart,
  guestCart,
  countProductsGuest,
  companyGuestCart,
  cartCompanyId,
  addProductLoading,
  meId,
  getPublication,
  btnPub = true,
  companyId,
  showEdit
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const fm = useFormatMessage();
  const { enqueueSnackbar } = useSnackbar();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const productId = useMemo(() => publication.id, [publication]);
  const [heightImg, setHeightImg] = useState(294);
  const [changePostsStatus, statusLoading] = useChangePostsStatus(() => getPublication({ page: 1, perPage: 20}))
  const [pagePosts, setPagePosts] = useState({ perPage: 20, page: 1 });
  const ref = useRef(null);
  const [alertStockOver, setAlertStockOver] = useState(false);


  const getStock = useCallback(
    (product: any) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );
  // const avatar = publication.company ? publication.company.logo : publication.user.avatar;
  // const headerTitle = publication.company
  //   ? publication.company.name || 'No name'
  //   : publication.user.fio || 'No name';

  const viewAction = useCallback(
    (item, type?: string) => {
      history.push({ pathname: `/viewproduct/${item.id}`, state: { type } });
    },
    [history]
  );

  useEffect(() => {
    const rootObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        const len = entries[0].target.clientWidth / 4;
        setHeightImg(entries[0].target.clientWidth + len);
      }
    );
    ref.current && rootObserver.observe(ref?.current);

    return () => {
      rootObserver.disconnect();
    };
  }, []);

  const handleCartDialog = useCallback(
    item => {
      const stock = isBuyer || !me ? item.stock_info?.available_quantity : item.stock_quantity;
      if (isAuthorized && (!stock || stock < 0)) {
        enqueueSnackbar(intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.ERROR' }), {
          variant: 'error',
        });
      } else if (isAuthorized && stock !== null) {
        addProductToCart({
          product_id: item.id ? item?.id : 0,
          count: 1,
        });
      } else if (!isAuthorized) {
        const countProduct = guestCart?.items.find(i => i.product.id === item.id)?.count || 0;
        if (!stock || countProduct === stock || countProduct > stock) {
          setAlertStockOver(true);
        } else {
          setProductGuestCart({ data: item, type: countProductsGuest === 0 ? 'new' : 'cart' });
        }
      }
    },
    [
      addProductToCart,
      cartCompanyId,
      companyGuestCart,
      setProductGuestCart,
      guestCart,
      me,
      isBuyer,
    ]
  );

  const handlePostStatus = useCallback(
   async (id: number) => {
    await changePostsStatus(id, 'approved')

  }, [publication]);


  return (
    <div className={isView ? classes.rootView : classes.root}>
      {isView && (
        <Typography
          style={{
            color: '#94959B',
            marginBottom: 10,
            marginTop: window.innerWidth < 1024 ? 25 : 0,
          }}
          variant='body2'
          component='p'
        >
          {moment(publication.created_at).locale('ru').format('D MMMM, h:mm')}
        </Typography>
      )}

      {isView && (
        <p
          style={{
            fontWeight: 600,
            color: '#212328',
            display: 'flex',
            wordWrap: 'break-word',
            fontSize: 35,
            lineHeight: 1.3,
          }}
        >
          {publication.title}
        </p>
      )}
      <CardMedia
        style={{ cursor: isView ? 'default' : 'pointer' }}
        className={classes.media}
        image={
          publication.avatar
            ? `${API_DOMAIN}/${publication?.avatar}`
            : toAbsoluteUrl('/images/placeholder.png')
        }
        onClick={() => history.push(`/blog/view/${publication.id}`)}
      />

      <div
        className={classes.content}
        style={{
          display: 'flex',
          flexDirection: isView ? 'row' : 'column',
          justifyContent: isView ? 'space-between' : 'center',
        }}
      >
        <div
          style={{
            padding: 0,
            width: isView && window.innerWidth >= 1024 ? '70%' : '100%'
          }}
        >
          {btnPub &&
            !isView &&
            isAdmin &&
            (publication.status === 'approved' ? (
              <Typography
                color='secondary'
                component='h4'
                variant='h6'
                style={{
                  marginBottom: 12,
                  lineHeight: 1.3,
                  marginTop: 5,
                  display: 'flex',
                  width: '47%',
                  marginLeft: 10,
                  justifyContent: 'flex-start',
                }}
              >
                <CheckIcon size='20' /> Опубликован
              </Typography>
            ) : (
              <ColorButton
                loading={statusLoading}
                color='#78dc78'
                style={{
                  width: '47%',
                  height: '35px',
                  color: 'white',
                  marginBottom: 2,
                }}
                onClick={() => handlePostStatus(publication.id)}
              >
                Опубликовать
              </ColorButton>
            ))}

          {!isView && (
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <Typography
                style={{ color: '#94959B', marginBottom: 2, paddingTop: 0, marginTop: 0 }}
                variant='body2'
                component='p'
              >
                {moment(publication.created_at).locale('ru').format('D MMMM, h:mm')}
              </Typography>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                }}
                className={isView ? classes.buttonViewContain : ''}
              >
                {!isView && (isAdmin || showEdit) && (
                  <div>
                    <Button
                    style={{padding: 0}}
                      onClick={() => history.push(`/blog/edit/${publication.id}`)}
                      color='primary'
                    >
                      <EditeIcon size='26' color='#78dc78' />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          {!isView && (
            <p
              className={clsx(classes.title, {
                [classes.titleIsAdmin]: isAdmin,
              })}
            >
              {publication.description}
            </p>
          )}

          {isView && (
            <>
              <Typography
                className={classes.textContent}
                variant='body1'
                component='div'
              >
                {parse(publication.text)}
              </Typography>
              <div
                style={{
                  width: '100%',
                  borderTop: '1px solid #D9D9D9',
                  paddingTop: 32,
                  marginTop: 48,
                  display: 'flex',
                }}
              >
                <img
                  style={{ width: 62, height: 62, borderRadius: '100%' }}
                  alt=''
                  src={`${API_DOMAIN}/${publication.user.avatar}`}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 16,
                  }}
                >
                  <Typography style={{ fontSize: 20, color: '#212328' }}>
                    {publication.user.fio}
                  </Typography>
                  <Typography style={{ fontSize: 16, color: '#212328', fontWeight: 400 }}>
                    Автор статьи
                  </Typography>
                </div>
              </div>
              <div />
            </>
          )}
        </div>

        {publication.product && isView && (
          <CardContent className={classes.card_content}>
            <Card key={publication.product.id} className={classes.productCard}>
              <CardMedia
                style={{
                  height: heightImg,
                  minHeight: 294,
                  objectFit: 'contain',
                }}
                ref={ref}
                image={
                  publication.product.photos[0]?.small
                    ? `${API_DOMAIN}/${publication.product.photos[0]?.small}`
                    : toAbsoluteUrl('/images/placeholder.png')
                }
                title={publication.product.name}
                onClick={() => viewAction(publication.product)}
              />
              <CardContent
                onClick={() => viewAction(publication.product)}
                className={classes.cardContent}
              >
                <p className={classes.price}>
                  {formatAsThousands(publication.product.price)} ₽
                </p>

                <Typography
                  color='inherit'
                  variant='body1'
                  component='div'
                  style={{
                    marginBottom: 5,
                    lineHeight: 1.3,
                    fontSize: 15,
                    fontWeight: 'revert-layer',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {publication.product.name}
                </Typography>
              </CardContent>
              <CardActions
                style={{ padding: '0px 7px 7px 7px', marginBottom: 5, marginTop: 5 }}
                className={classes.containerBtns}
              >
                <div className={classes.btnAddProduct}>
                  {isAdmin || isVendor ? (
                    <Button
                      style={{ width: '100%', margin: '0 5px', color: '#BDC3C7' }}
                      variant='outlined'
                      disabled={
                        addProductLoading ||
                        !getStock(publication.product) ||
                        (getStock(publication.product) || 0) < 0
                      }
                      onClick={() => {
                        handleCartDialog(publication.product);
                      }}
                      color={
                        !isAdmin ||
                        !getStock(publication.product) ||
                        (getStock(publication.product) || 0) < 0
                          ? 'secondary'
                          : 'default'
                      }
                    >
                      {!getStock(publication.product) ||
                      (getStock(publication.product) || 0) < 0
                        ? fm('PRODUCT.BUTTON.SOLD.OUT')
                        : fm('PRODUCT.BUTTON.ADD_CART')}
                    </Button>
                  ) : (
                    <Button
                      style={{ width: '100%', margin: '0 5px' }}
                      variant='outlined'
                      disabled={
                        addProductLoading ||
                        !getStock(publication.product) ||
                        (getStock(publication.product) || 0) < 0
                      }
                      onClick={() => {
                        handleCartDialog(publication.product);
                      }}
                      color={
                        !isAdmin ||
                        !getStock(publication.product) ||
                        (getStock(publication.product) || 0) < 0
                          ? 'secondary'
                          : 'primary'
                      }
                    >
                      {!getStock(publication.product) ||
                      (getStock(publication.product) || 0) < 0
                        ? fm('PRODUCT.BUTTON.SOLD.OUT')
                        : fm('PRODUCT.BUTTON.ADD_CART')}
                    </Button>
                  )}
                </div>
              </CardActions>
            </Card>
          </CardContent>
        )}
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    products: state.productsCatalog.products,
    productsForDays: state.productForDays.productsForDays,
    productsFavorites: state.productsCatalog.productsFavorites,
    loading: state.productsCatalog.loading,
    page: state.productsCatalog.page,
    perPage: state.productsCatalog.per_page,
    filter: state.productsCatalog.filter,
    deleteError: state.productsCatalog.delError,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    addProductLoading: state.cart.addProductLoading,
    addProductSuccess: state.cart.addProductSuccess,
    addProductError: state.cart.addProductError,
    cartCompanyId: state.cart.companyId,
    isAuthorized: state.auth.user != null,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    guestCart: state.cart.guestCart,
    catalogCategories: state.categories.catalogCategories,
    isSearching: state.productsCatalog.isSearching,
    search: state.productsCatalog.search,
    sortType: state.productsCatalog.sortType,
    productsCount: state.productsCatalog.total,
    typeParams: state.productTypes.typeParams,
    productTypes: state.productTypes.productTypes,
    editSuccess: state.products.editSuccess,
    editError: state.products.editError,
    delSuccess: state.productsCatalog.delSuccess,
    editHideSuccess: state.products.editHideSuccess,
    editHideError: state.products.editHideError,
  }),
  {
    fetch: productCatalogActions.fetchRequest,
    clearProducts: productCatalogActions.clearProducts,
    clearFilter: productCatalogActions.clearFilter,
    delProduct: productCatalogActions.delRequest,
    clearDel: productCatalogActions.clearDel,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    edit: productActions.editRequest,
    editHide: productActions.editHideRequest,
    addProductToCart: cartActions.addProductRequest,
    setProductGuestCart: cartActions.setProductGuestCart,
    fetchCatalogCategories: categoriesActions.fetchCatalogCategories,
    fetchProductsForDays: productForDaysActions.fetchRequest,
    fetchFavorites: productCatalogActions.fetchProductsFavorites,
    setSearch: productCatalogActions.setSearch,
    fetchTypeById: productTypeActions.fetchParamsByIdRequest,
    fetchType: productTypeActions.fetchRequest,
    clearParams: productTypeActions.clearParams,
    clearAddProduct: cartActions.clearAddProduct,
    clearEdit: productActions.clearEdit,
    getPublication: blogActions.publicationsRequest

  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PublicationPost);
