import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TablePaginator from '../../../../components/TablePaginator/TablePaginator';
import { API_DOMAIN } from '../../../../constants';
import { formatAsThousands } from '../../../../utils/utils';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser, UserRoles } from '../../../../interfaces/user';
import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';
import Preloader from '../../../../components/other/Preloader/Preloader';
import StarActive from './StarActive';
import StarDisable from './StarDisable';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      minWidth: '100%',
      marginBottom: 25,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',

      '&:hover': {
        transform: 'scale(1.03)',
      },

      [theme.breakpoints.up('md')]: {
        width: 220,
      },
    },
    containerBtns: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    btnSupport: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
    },
    btnAddProduct: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: 45,
      [theme.breakpoints.down('md')]: {
        // marginBottom: 10,
      },
    },
    cardContent: { padding: '15px 15px 0 15px', height: 130, overflow: 'hidden' },
    columnContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  })
);

interface IProductsProps {
  data: IProduct[];
  page: number;
  perPage: number;
  total: number;
  categoryId: string;
  companyId: string;
  fetch: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction: any;
  handleDeleteDialog: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  isPaginator?: boolean;
  edit?: any;
  isHideProduct?: boolean;
  isSearching?: boolean;
}

const ProductsGrid: FC<IProductsProps> = ({
  data,
  categoryId,
  page,
  perPage,
  total,
  fetch,
  companyId,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAuthorized,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  isPaginator = true,
  edit,
  isHideProduct,
  isSearching,
}) => {
  const fm = useFormatMessage();
  const classes = useStyles();
  const ref = useRef(null);
  const [heightImg, setHeightImg] = useState(294);
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const history = useHistory();
  const [createChatFetch, loadingCreated] = useCreateChat();

  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  useEffect(() => {
    const rootObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        const len = entries[0].target.clientWidth / 3;
        setHeightImg(entries[0].target.clientWidth + len);
      }
    );
    ref.current && rootObserver.observe(ref?.current);

    return () => {
      rootObserver.disconnect();
    };
  }, []);

  const changeHide = useCallback((item: IProduct) => {
    if (isHideProduct) {
      const data = new FormData();
      data.append('active', '0');
      edit({ id: Number(item?.id), data });
    }
  }, []);

  const newChat = useCallback((userId: string | number) => {
    userId && createChatFetch({ userId });
  }, []);

  if (loadingCreated) return <Preloader />;

  return (
    <>
      <div className={classes.container}>
        {data.map(item => {
          const imageURL =
            item.photos &&
            item.photos.length > 0 &&
            item.photos.filter(photo => Boolean(photo.main));
          if (
            !(
              (!getStock(item) || (getStock(item) || 0) < 0) &&
              !me?.roles.includes(UserRoles.ROLE_VENDOR) &&
              !isAdmin
            )
          ) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <CardMedia
                  style={{
                    height: heightImg,
                    minHeight: 294,
                    objectFit: 'contain',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  ref={ref}
                  image={
                    imageURL && imageURL.length && !!imageURL[0].small
                      ? `${API_DOMAIN}/${imageURL[0].small}`
                      : toAbsoluteUrl('/images/placeholder.png')
                  }
                  title={item.name}
                  onClick={() => {
                    viewAction(item);
                  }}
                />
                <CardContent
                  onClick={() =>
                    viewAction(
                      item
                      // 'view_from_catalog'
                    )
                  }
                  className={classes.cardContent}
                >
                <div className={classes.columnContent}>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{ lineHeight: 1.3, alignItems:'center',paddingTop:'5px' }}
                  >
                    <b>{formatAsThousands(item.price)} ₽</b>
                  </Typography>

                  <div className={classes.reviewContent}>
                    <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{ lineHeight: -1,paddingTop:'-5px' }}
                  >
                    <b>{item?.rating ? <StarActive size='28' /> : <StarDisable size='28' />}</b>
                  </Typography>

                    <Typography
                      gutterBottom
                      variant='h5'
                      component='h2'
                      style={{ lineHeight: 1.3, alignItems:'center',paddingTop:'5px'}}
                    >

                    <b>{formatAsThousands(item.rating || '')}</b>
                    </Typography>
                  </div>
                </div>    
                  <Typography
                    color='inherit'
                    component='p'
                    style={{ marginBottom: 5, lineHeight: 1.3 }}
                  >
                    {item.name}
                  </Typography>
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions
                  style={{ padding: '0px 7px 7px 7px' }}
                  className={classes.containerBtns}
                >
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div className={classes.btnAddProduct}>
                      <Button
                        style={{ width: '100%', margin: '0 5px' }}
                        variant='outlined'
                        disabled={
                          addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color={
                          !getStock(item) || (getStock(item) || 0) < 0
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {!getStock(item) || (getStock(item) || 0) < 0
                          ? fm('PRODUCT.BUTTON.SOLD.OUT')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                  {isAdmin && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item);
                          changeHide(item);
                        }}
                        color='secondary'
                        style={{ marginLeft: 8 }}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                  {/* {(me?.roles.includes(UserRoles.ROLE_VENDOR) ||
                    me?.roles.includes(UserRoles.ROLE_BUYER)) && (
                    <div className={classes.btnSupport} style={{ margin: 0 }}>
                      <Button
                        style={{ width: '100%', margin: '0 5px' }}
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                          history.push({
                            pathname: `/chats`,
                            state: {
                              createUserChat: me,
                            },
                          });
                        }}
                      >
                        {fm('Поддержка')}
                      </Button>
                    </div>
                  )} */}
                </CardActions>
              </Card>
            );
          }
          if (!isAdmin && item?.company?.favorite) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <CardMedia
                  style={{
                    height: heightImg,
                    minHeight: 294,
                    objectFit: 'contain',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  ref={ref}
                  image={
                    imageURL && imageURL.length && !!imageURL[0].small
                      ? `${API_DOMAIN}/${imageURL[0].small}`
                      : toAbsoluteUrl('/images/placeholder.png')
                  }
                  title={item.name}
                  onClick={() => viewAction(item)}
                />
                <CardContent onClick={() => viewAction(item)} className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{ lineHeight: 1 }}
                  >
                    <b>{formatAsThousands(item.price)} ₽</b>
                  </Typography>
                  <Typography
                    color='inherit'
                    component='p'
                    style={{ marginBottom: 5, lineHeight: 1.3 }}
                  >
                    {item.name}
                  </Typography>
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions style={{ padding: '0px 7px 7px 7px' }}>
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div className={classes.btnSupport}>
                      <Button
                        disabled={
                          addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color={
                          !getStock(item) || (getStock(item) || 0) < 0
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {!getStock(item) || (getStock(item) || 0) < 0
                          ? fm('PRODUCT.BUTTON.SOLD.OUT')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                  {isAdmin && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item);
                          changeHide(item);
                        }}
                        color='secondary'
                        style={{ marginLeft: 8 }}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            );
          }
          if (
            !(
              (!getStock(item) || (getStock(item) || 0) < 0) &&
              me?.company?.id === item?.company?.id
            ) &&
            !item.company?.favorite
          ) {
            return (
              <Card key={item.id} className={classes.productCard}>
                <CardMedia
                  style={{
                    height: heightImg,
                    minHeight: 294,
                    objectFit: 'contain',
                    // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                  }}
                  ref={ref}
                  image={
                    imageURL && imageURL.length && !!imageURL[0].small
                      ? `${API_DOMAIN}/${imageURL[0].small}`
                      : toAbsoluteUrl('/images/placeholder.png')
                  }
                  title={item.name}
                  onClick={() => {
                    viewAction(item);
                  }}
                />
                <CardContent
                  onClick={() =>
                    viewAction(
                      item
                      // 'view_from_catalog'
                    )
                  }
                  className={classes.cardContent}
                >
                  <Typography
                    gutterBottom
                    variant='h5'
                    component='h2'
                    style={{ lineHeight: 1 }}
                  >
                    <b>{formatAsThousands(item.price)} ₽</b>
                  </Typography>
                  <Typography
                    color='inherit'
                    component='p'
                    style={{
                      marginBottom: 5,
                      lineHeight: 1.3,
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {item.name}
                  </Typography>
                  {/* <Typography color='textSecondary' component='p'>
                    {item.modification.gearbox_type.name}
                  </Typography> */}
                </CardContent>
                <CardActions style={{ padding: '0px 7px 7px 7px' }}>
                  {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div className={classes.btnSupport}>
                      <Button
                        disabled={
                          addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color={
                          !getStock(item) || (getStock(item) || 0) < 0
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {!getStock(item) || (getStock(item) || 0) < 0
                          ? fm('PRODUCT.BUTTON.SOLD.OUT')
                          : fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                  {isAdmin && (
                    <>
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>

                      <Button
                        onClick={() => {
                          handleDeleteDialog(item);
                          changeHide(item);
                        }}
                        color='secondary'
                        style={{ marginLeft: 8 }}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            );
          }
        })}
      </div>
      {isPaginator && (
        <div style={{ display: 'flex', justifyContent: 'center' }} id='catalog-paginator'>
          <TablePaginator
            id={+categoryId}
            page={page}
            perPage={perPage}
            total={total}
            realPerPage={data.length}
            fetchRows={fetch}
            companyId={companyId}
            rowsPerPageOptions={[12, 36, 108]}
            label={fm('PRODUCT.TABLE.PERPAGE')}
          />
        </div>
      )}
    </>
  );
};

export default ProductsGrid;
