import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

// import '@formatjs/intl-relativetimeformat/polyfill';
// import '@formatjs/intl-relativetimeformat/locale-data/en';

import ruMessages from './messages/ru';

const allMessages = {
  ru: ruMessages,
};

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages['ru'];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
