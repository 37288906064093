import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import  {Typography} from '@material-ui/core';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { useGetReviews } from '../hooks/useGetReviews';
import { useDeleteReview } from '../hooks/useDeleteReview';
import { ReviewsStyles } from '../hooks/ReviewsStyles';
import { useDefineUserRole } from '../../../../hooks';
import Rating from './Rating';
import { API_DOMAIN } from '../../../../constants';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { IProduct } from '../../../../interfaces/product';
import { TextType, Text } from '../../../../components/ui/Text';
import { colors } from '../../../../constants/colors';
import { ColorButton } from '../../../../components/other/Buttons';
import { useChangeReviewStatus } from '../hooks/useChangeStatusReview';
import CheckIcon from './CheckIcon';

interface IProps {
  meAdd?: boolean;
  product: IProduct;
  name: string;
  isAuthorized?: boolean;
}

const ReviewsTextComponent: React.FC<IProps> = ({ meAdd = false, product, name, isAuthorized }) => {
  const classes = ReviewsStyles();
  const reviewsProduct = useSelector(
    ({ products: { reviewsProduct } }: IAppState) => reviewsProduct,
    shallowEqual
  );
  const me = useSelector(({ profile: { me } }: IAppState) => me, shallowEqual);
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const productId = useMemo(() => product.id, [product]);

  const [fetchReviews, loading] = useGetReviews();
  const [deleteReview] = useDeleteReview();
  const [changeReviewStatus, statusLoading] = useChangeReviewStatus(() => fetchReviews(productId, pageReviews.perPage, pageReviews.page))
  const [pageReviews, setPageReviews] = useState({ perPage: 20, page: 1 });

  useEffect(() => {
    productId && fetchReviews(productId, pageReviews.perPage, pageReviews.page);
  }, [productId, fetchReviews]);

  const handleDeleteReview = useCallback(
    (id: number) => {
      deleteReview(id, productId, pageReviews.perPage, pageReviews.page);
    },
    [productId, pageReviews]
  );


  const handleReviewStatus = useCallback(
    (id: number) => {
      changeReviewStatus(id, 'approved')
  }, []);

  if (loading) return <Preloader />;

  return (
    <>
      <div className={classes.headerTitle}>Отзывы на товар</div>
      <div className={classes.reviewsText}>
        <div className={classes.reviewsList}>
          {reviewsProduct && reviewsProduct.reviews.length > 0 && (
            <>
              {reviewsProduct.reviews.map((item) => (
                (!isAuthorized || isBuyer) && item.status === 'new' ? null
                :
                <div style={{ marginBottom: 32 }}>
                  <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                    <div className={classes.avatar}>
                      <img
                        alt={item.user.fio}
                        src={
                          item.user.avatar
                            ? `${API_DOMAIN}/${item.user.avatar}`
                            : toAbsoluteUrl('/images/placeholder.png')
                        }
                      />
                    </div>

                    <div className={classes.retingContent}>
                      <Text className={classes.textContent} type={TextType.semiboldSubtitle1} padding={{top:10}} margin={{ bottom: 4 }}>
                        {item.user.fio}
                      </Text>

                      <Rating rating={item.rating} />
                    </div>
                  </div>

                  <Text>{item.text}</Text>

                  <div className={classes.subButtonContainer}>
                    {(isAdmin || item.user?.id === me?.id) && (
                      <ColorButton
                        color='#fff'
                        className={classes.button}
                        style={{
                          color: '#002626',
                          border: '2px solid #002626',
                          height: '35px',}}
                        onClick={()=> handleDeleteReview(item.id)}>
                        Удалить отзыв
                    </ColorButton>
                    )}

                    {(isAdmin) && (
                      item.status === 'approved' ?
                      <Typography
                        color='secondary'
                        component='h4'
                        variant='h6'
                        style={{
                          marginBottom: 5,
                          lineHeight: 1.3,
                          marginTop: 5,
                          display:'flex',
                          width:'47%',
                          justifyContent:'center'}}
                        >
                        <CheckIcon size="20"/> Опубликован
                      </Typography>
                      :
                     <ColorButton
                        loading={statusLoading}
                        color="#78dc78"
                        className={classes.button}
                        style={{
                          height: '35px',
                          color: 'white',}}
                        onClick={()=> handleReviewStatus(item.id)}>
                        Опубликовать
                      </ColorButton>
                    )}
                  </div>
                </div>
              ))}

              <div className={classes.subButtonContent}>
                {reviewsProduct.total !== reviewsProduct.reviews.length && (
                  <Text
                    style={{ cursor: 'pointer' }}
                    color={colors.primary.green}
                    type={TextType.semiboldButton}
                    onClick={() => {}}
                  >
                    Загрузить больше отзывов
                  </Text>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewsTextComponent;
