import { ICompany } from './companies';
import { ILocation } from './locations';

export interface IUserAddProps {
  login: string | undefined;
  email: string | undefined;
  password: string;
  roles: string[];
  fio: string | undefined;
}

export interface IUserEditProps {
  login: string | undefined;
  email: string | undefined;
  password?: string;
  fio: string | undefined;
  active?: boolean;
  locale?: string;
  attributes?: TUserAttributes;
  bonus_count?: number;
}

export enum ManagerStatus {
  moderation = 'In moderation',
  hired = 'Hired',

  // Only in front
  canceled = 'Canceled',
}

export type TUserAttributes = { type: string; value: string }[];

export interface IUser {
  id: number;
  email: string;
  api_token: string;
  fio: string;
  login: string;
  is_admin: boolean;
  active: boolean;
  company: ICompany | null;
  roles: [UserRoleType];
  locale: string;
  manager_status: ManagerStatus;
  location?: ILocation | null;
  phone: string;
  phone_numbers: string;
  avatar: string;
  loyalty_level: null | {
    orders_summ: number;
    discount_percent: number;
  };
  attributes: TUserAttributes;
  current_user_tariff: any;
  country?: any;
  bonus_count?: any;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
}

export type TUserAttr = {
  languages: string[];
  country: string[];
};

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
  ROLE_VENDOR = 'ROLE_VENDOR',
  ROLE_BUYER = 'ROLE_BUYER',
}

export type UserRoleType =
  | 'ROLE_ADMIN'
  | 'ROLE_MANAGER'
  | 'ROLE_USER'
  | 'ROLE_VENDOR'
  | 'ROLE_BUYER';

export enum LoginType {
  email = 'email',
  phone = 'phone',
  login = 'login',
}
