import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  root: {paddingTop: 40},
  dropZone: {
    border: `2px dashed ${theme.palette.divider}`,
    padding: '45px 100px',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'default',
  },
  dropZoneActive: {
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
  selectedDropZone: {
    borderColor: '#0abb87',
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: 15,
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 35,
  },
}));
