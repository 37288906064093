import React, { useState, useCallback, useEffect } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  Button,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ReactComponent as Clear } from '../../../../_metronic/layout/assets/layout-svg-icons/clear-icon.svg';
import { ReactComponent as Search } from '../../../../_metronic/layout/assets/layout-svg-icons/search-icon.svg';
import { ReactComponent as Home } from '../../../../_metronic/layout/assets/layout-svg-icons/home.svg';
import { ReactComponent as HomeActive } from '../../../../_metronic/layout/assets/layout-svg-icons/home_active.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    textFieldWrapper: {
      width: '100%',
      maxWidth: 'calc(100% - 326px - 30px)',
      minWidth: 'calc(326px)',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
      },
    },
    textFieldWrapperSubHeader: {
      width: '100%',
      maxWidth: 'calc(100%)',
      display: 'flex',
      alignItems: 'center',
      margin: '0 20px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        marginTop: 15,
        marginBottom: 15,
        margin: '0 20px',
      },
    },
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
        color: '#002626',
        fontWeight: 500,
      },
      '& .MuiButton-root': {
        borderRadius: '0 4px 4px 0',
      },
      '& .MuiButton-containedSizeLarge': {
        padding: '7px 22px',
      },
      '& .MuiButton-contained': {
        boxShadow: 'none',

      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      }
    },
    clear: {
      padding: '0 10px 0 10px',
      fontWeight: 'bold',
      color: '#b5b5c3',
      cursor: 'pointer',
    },
    home: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 20,
      cursor: 'pointer',
    },
    homeText: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
  })
);

const SearchField = ({
  isSubHeader = false,
  clear,
}: {
  isSubHeader?: boolean;
  clear?: () => void;
}) => {
  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const { page, perPage, filter, isSearching,search ,sortType} = useSelector(
    ({ productsCatalog }: any) => productsCatalog
  );
  const { fetchRequest, setSearch } = productCatalogActions;

  useEffect(() => {
      if (isSearching === false || search === ""  ) {
          setSearchText('');
      }
  }, [isSearching, search]);
  const searchProducts = useCallback((page, perPage, filter, searchText,sort_type) => {
    let searchStatus;
    if (searchText === '') searchStatus = false;
    else searchStatus = true;
    dispatch(setSearch(searchStatus));
    dispatch(fetchRequest({ page, perPage, filter, search:searchText, sort_type }));
    if (history.location.pathname === '/main/catalog') {
      history.push('/products/catalog');
    }
  }, [sortType]);

  return (
    <form
      className={isSubHeader ? classes.textFieldWrapper : classes.textFieldWrapperSubHeader}
      onSubmit={e => {
        e.preventDefault();
        searchProducts(page, perPage, filter, searchText,sortType);
      }}
    >
      <TextField
        className={classes.textField}
        variant='outlined'
        size='small'
        focused={isSubHeader ? undefined : true}
        placeholder={isSubHeader ? 'Искать на RUHERB PRO' : 'Искать на RUHERB PRO'}
        onChange={e => setSearchText(e.target.value)}
        value={searchText}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' style={{ margin: 0}}>
              <span
                className={classes.clear}
                onClick={() => {
                  setSearchText('');
                  searchProducts(page, perPage, filter, '',sortType);
                }}
              >
                {isSubHeader ? intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' }) : <Clear />}
              </span>
              <Button
                style={
                  isSubHeader
                    ? {}
                    : {
                        maxWidth: 42,
                        minWidth: 42,
                        margin: 0,
                        padding: 0,
                        height: 35,
                      }
                }
                onClick={() => searchProducts(page, perPage, filter, searchText,sortType)}
                color='primary'
                variant='contained'
                size='large'
              >
                {isSubHeader ? (
                  intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' })
                ) : (
                  <Search />
                )}
              </Button>
            </InputAdornment>
          ),
        }}
      />
      {!isSubHeader && (
        <Link
          className={classes.home}
          to='/main/catalog'
          onClick={() => {
            clear && clear();
          }}
        >
          {location.pathname === '/main/catalog' ? <HomeActive /> : <Home />}
          <div
            className={`kt-menu__link-text  ${classes.homeText}`}
            style={{
              fontWeight: 700,
              color: location.pathname === '/main/catalog' ? '#78DC78' : '#005A5A',
            }}
          >
            {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          </div>
        </Link>
      )}
    </form>
  );
};

export default SearchField;
