import axios from 'axios';
import { IOrderForEdit } from '../interfaces/order';
import { getOrdersByStatus, getOrderurl } from '../constants';

export const getOrders = (
  statuses: string,
  page: number,
  per_page: number,
  userId?: number,
  accord_order_state?: string,
  payment_status?: string,
  company_id?: string,
  date_from?: string,
  date_to?: string,
  hide_delivery_cost?: boolean,
  csv?: boolean
) => {
  const boxberry = company_id?.toString() === '-2';
  return axios.get(`${getOrdersByStatus(statuses)}&page=${page}&per_page=${per_page}`, {
    params: {
      company_id: boxberry ? undefined : company_id,
      user_id: userId,
      accord_order_state,
      payment_status,
      date_from,
      date_to,
      hide_delivery_cost,
      csv,
      boxberry,
    },
  });
};

export const deleteOrder = (id: number) => axios.delete(getOrderurl(id));

export const editOrder = (id: number, order: IOrderForEdit) =>
  axios.put(getOrderurl(id), order);

export const getOrderById = (id: number) => axios.get(getOrderurl(id));
