import axios from 'axios';
import {
  createReviewUrl,
  delPhotoFromProduct,
  delReviewUrl,
  FOR_DAYS_URL,
  getProductByIdUrl,
  getReviewsUrl,
  PRODUCTS_FAVORITES_URL,
  PRODUCTS_URL,
  setProductMainPhotoUrl,
  setStockExportUrl,
} from '../constants';
import { IProductFilter, IReviewCreate, IProduct } from '../interfaces/product';

export const createProduct = (body: FormData) => axios.post(`/api/shop/product`, body);

export const editProduct = (id: number, data: FormData) => {
  return axios.post(`/api/shop/product/${id}`, data);
};

export const editProductFav = (id: number, favorite: boolean, data: IProduct) => {
  return axios.post(`/api/shop/product/${id}`, data, { params: { favorite } });
};

export const editStock = (product_id: number, body: FormData) =>
  axios.post(`/api/stock_info/${product_id}`, body);

export const setExport = (id: number) => axios.post(setStockExportUrl(id));

export const deleteProduct = (id: number | undefined) =>
  axios.delete(getProductByIdUrl(id || -1));

export const getProductById = (
  id: number
  // from_catalog?: boolean, from_favorite?: boolean
) => {
  // if (from_catalog) { return axios.get(`${getProductByIdUrl(id)}?from_catalog=${from_catalog}`) }
  // if (from_favorite) { return axios.get(`${getProductByIdUrl(id)}?from_favorite=${from_favorite}`) }
  return axios.get(getProductByIdUrl(id));
};

export const getProductFavorites = () => axios.get(PRODUCTS_FAVORITES_URL);

export const getProducts = ({
  page = 1,
  perPage = 10,
  companyId,
  categoryId,
  search,
  filter = {},
  isFavorites,
  sort_type,
}: {
  page: number;
  perPage: number;
  companyId?: number;
  categoryId?: number;
  search?: string;
  filter?: Partial<IProductFilter>;
  isFavorites?: boolean;
  sort_type?: string;
}) => {
  const place = JSON.parse(localStorage.getItem('place') || 'false');
  if (search) {
    let url = `/api/shop/products/search/?page=${page}&per_page=${perPage}`;
    if (search) url += `&text=${search}`;
    if (sort_type) {
      url += `&sort_type=${sort_type}`;
    }
    return axios.get(url);
  }
  if (companyId) {
    let url = `/api/shop/company/${companyId}/products?page=${page}&per_page=${perPage}&sort_type=${sort_type}`;
    if (categoryId) url += `&category_id=${categoryId}`;
    return axios.get(url);
  }
  if (categoryId) {
    if (!filter?.parameters) {
      return axios.post(
        `/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}&sort_type=${sort_type}`
      );
    }
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(
      `/api/shop/category/${categoryId}/products?page=${page}&per_page=${perPage}&sort_type=${sort_type}`,
      formData, {params: {
        min_price: filter.min_price,
        max_price: filter.max_price
      }}
    );
  }
  if (filter?.parameters) {
    const formData = new FormData();
    formData.append('parameters', JSON.stringify(filter.parameters));
    return axios.post(
      `/api/shop/category/0/products?page=${page}&per_page=${perPage}&sort_type=${sort_type}`,
      formData,{params: {
        min_price: filter.min_price,
        max_price: filter.max_price
      }}
    );
  }
  if (place) {
    return axios.post(
      `/api/shop/category/0/products?page=${page}&sort_type=${sort_type}&per_page=${perPage}&lat=${place.geometry.location.lat}&lng=${place.geometry.location.lng}`
    );
  }
  if (isFavorites) {
    return getProductFavorites();
  }
  const body = {
    min_price: filter?.min_price ? +filter.min_price : null,
    max_price: filter?.max_price ? +filter.max_price : null,
  };
  return axios.post(PRODUCTS_URL, body, {
    params: {
      page,
      per_page: perPage,
    },
  });
};

export const delPhoto = (photoId: number) => axios.delete(delPhotoFromProduct(photoId));

export function setMainPhoto(id: number) {
  return axios.post(setProductMainPhotoUrl(id));
}

export const getReviews = (productId: number, perPage: number, page: number) =>
  axios.get(getReviewsUrl(productId, perPage, page));

export const createReview = (productId: number, body: IReviewCreate) =>
  axios.post(createReviewUrl(productId), body);

export const delReview = (reviewId: number) => axios.delete(delReviewUrl(reviewId));

export const getProductsForDays = ({
  page = 1,
  perPage = 10,
  forDays = 0,
}: {
  page: number;
  perPage: number;
  forDays: number;
}) =>
  axios.post(
    PRODUCTS_URL,
    {
      page,
      per_page: perPage,
      for_days: forDays,
    },
    { params: { page, per_page: perPage } }
  );

export const getForDays = () => axios.get(FOR_DAYS_URL);
export const addForDays = (days: number) => axios.post(`${FOR_DAYS_URL}?days=${days}`);

export const addProductSubscribes = (productId: number, email: string) => {
  const formData = new FormData();
  formData.append('product_id', JSON.stringify(productId));
  axios.post(`/api/product_subscribes/add?email=${email}`, formData);
};

export const delProductSubscribes = (productId: number, subscribeId: number) => {
  axios.delete(`/api/product_subscribe?subscribe_id=${subscribeId}&product_id=${productId}`);
};

export const editReviewStatus = (review_id: number, status: string) =>
  axios.put(`/api/shop/review_status/${review_id}`, {}, { params: { status } });
