import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import HMenuItem from './HMenuItem';
import * as builder from '../../../ducks/builder';
import { toAbsoluteUrl } from '../../..';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';
import { ReactComponent as Logo } from '../../assets/layout-svg-icons/ruherb-logo4.svg';
import { useSelector, useDispatch } from 'react-redux';
import { actions as productCatalogActions } from '../../../../app/store/ducks/product-catalog.duck';
import { useHistory } from 'react-router-dom';



const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active',
  },
};

class HMenu extends React.Component {
  offCanvasCommonRef = React.createRef();
  ktMenuCommonRef = React.createRef();

  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  componentDidMount() {
    // Canvas
    this.initOffCanvas();
    // Menu
    this.initKTMenu();
  }

  initOffCanvas = () => {
    new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
  };

  initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion',
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = 'accordion';
    if (this.ktMenuCommonRef.current.getAttribute('data-ktmenu-dropdown') === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    // eslint-disable-next-line no-undef
    new KTMenu(this.ktMenuCommonRef.current, menuOptions);
  };

  render() {
    const {
      disabledAsideSelfDisplay,
      ktMenuClasses,
      ulClasses,
      rootArrowEnabled,
    } = this.props;
    const items = this.props.menuConfig?.aside.items;

    return (
      <>
        <button
          style={{ backgroundColor: 'rgb(0, 90, 90, 0.3)' }}
          className='kt-header-menu-wrapper-close' id='kt_header_menu_mobile_close_btn'>
          <i
            style={{ color: 'rgb(0, 90, 90)' }}
            className='la la-close' />
        </button>
        <div
          className='kt-header-menu-wrapper'
          id='kt_header_menu_wrapper'
          ref={this.offCanvasCommonRef}
        >
          <div className='kt-header-mobile__toolbar'>
            {/* <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button> */}
          </div>

          {disabledAsideSelfDisplay && (
            <LogoComponent />

          )}

          <div
            id='kt_header_menu'
            className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
            ref={this.ktMenuCommonRef}
          >
            <ul className={`kt-menu__nav ${ulClasses}`}>
              {items?.map((item, index) => {
                return (
                  <React.Fragment key={`hmenuList${index}`}>
                    {item && item.title &&  (
                      <HMenuItem
                        item={item}
                        currentUrl={this.currentUrl}
                        rootArrowEnabled={rootArrowEnabled}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: 'header_menu',
    toString: true,
  }),
  rootArrowEnabled: builder.selectors.getConfig(store, 'header.menu.self.root-arrow'),
  headerSelfSkin: builder.selectors.getConfig(store, 'header.self.skin'),
  ulClasses: builder.selectors.getClasses(store, {
    path: 'header_menu_nav',
    toString: true,
  }),
  disabledAsideSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false,
});

export default withRouter(connect(mapStateToProps)(HMenu));



const LogoComponent = () => {
  const history = useHistory();

  const dispatch = useDispatch()

  const { page, perPage, filter, isSearching } = useSelector(({ productsCatalog }) => productsCatalog);
  const { fetchRequest, setSearch } = productCatalogActions;

  const toInitial = useCallback((page, perPage, filter, search) => {
    history.push('/main/catalog')
    if (isSearching) {
      dispatch(setSearch(search))
      dispatch(fetchRequest({ page, perPage, filter, search }))
    }
  }, [isSearching]);

  return (
    <div className='kt-header-logo'>
      <Link to='/main/catalog' onClick={(e) => {
        e.preventDefault()
        toInitial(page, perPage, filter, false)
      }}>
        <p style={{ margin: '-5px 0 0 0 ', fontWeight: 'bold', fontSize: 15, color: '#646c9a', width: '180px', }}>
          <Logo />
        </p>
      </Link>
    </div>
  )
}
