import { useMemo, useState } from 'react';

export const useHandleCountrySelect = (countries: any[], initCountryId: number = 1) => {
  const [countryId, setCountryId] = useState(initCountryId);

  const countryData = useMemo(() => {
    if (!countries) return null;
    return countries.find(el => el.id === countryId);
  }, [countries, countryId]);

  return { countryId, setCountryId, countryData };
};
